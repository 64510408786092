import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

import firebase from 'firebase';
import { Adsense } from '@ctrl/react-adsense';
import Swal from 'sweetalert2'
import { Payment, StatusScreen } from '@mercadopago/sdk-react';
import { geraPreferencia } from '../../services/MercadoPago'
import moment from 'moment';
const urlProd = "https://us-central1-fabrica-de-sonhos-virtua-e8cae.cloudfunctions.net/app"
const urlHomo = "http://127.0.0.1:5001/fabrica-de-sonhos-virtua-e8cae/us-central1/app"


export default function Checkout() {
    const queryParams = new URLSearchParams(window.location.search);
    const [idCurso] = useState(queryParams.get('c'))
    const [idUsuario] = useState(queryParams.get('u'))
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios').doc(idUsuario),
        curso: firebase.firestore().collection('usuarios').doc(idUsuario).collection('cursos').doc(idCurso),
        cursos: firebase.firestore().collection('cursos')
    })
    const [curso, setCurso] = useState([])
    const [usuario, setUsuario] = useState([])
    const [preferencia, setPreferencia] = useState('')
    const [status, setStatus] = useState(false)
    const [pagamento, setPagamento] = useState(false)
    const [loading, setLoading] = useState(true)

    // Payments
    const initialization = {
        amount: curso.valor,
        preferenceId: preferencia,
    };
    const customization = {
        paymentMethods: {
            ticket: "all",
            bankTransfer: "all",
            creditCard: "all",
            debitCard: "all",
            mercadoPago: "all",
        },
    };

    useEffect(() => {
        servicos.curso.get().then((res) => {
            setCurso(res.data())
            setLoading(false)
        })
        servicos.usuarios.get().then((res) => {
            setUsuario(res.data())
        })
    }, [])

    function criaPreferencia() {
        mensagemLoading('Gerando Formas de Pagamento...')
        const dados = {
            items: [
                {
                    id: curso.id,
                    title: curso.titulo,
                    description: curso.descricao,
                    picture_url: curso.miniatura,
                    category_id: "tecnologia",
                    quantity: 1,
                    currency_id: "BRL",
                    unit_price: curso.valor,
                }
            ],
            payment_methods: {
                installments: 5
            },
            payer: {
                name: usuario.nome,
                surname: usuario.sobrenome,
                email: usuario.email,
                phone: {
                    area_code: 55,
                    number: usuario.celular
                },
                address: {
                    street_name: "Rua Falsa 123",
                    zip_code: "65073480",
                    street_number: 17
                },
            },
            auto_return: "all",
            external_reference: curso.id,
            back_urls: {
                success: window.location.host + "/sucesso",
                pending: window.location.host + "/sucesso",
                failure: window.location.host + "/sucesso",
            },
            notification_url: "https://webhook.site/1cc95697-ee59-4a01-89a4-a352f892ab8b",

        }
        geraPreferencia(dados, null).then((res) => {
            servicos.usuarios.collection('cursos').doc(curso.id).update({
                preferencia: res.id,
                status: 'PAGAMENTO'
            }).then(() => {
                Swal.close()
                setPagamento(true)
                setPreferencia(res.id)
            })
        })
    }

    const onSubmit = async (
        { selectedPaymentMethod, formData }
    ) => {
        // callback chamado ao clicar no botão de submissão dos dados
        return new Promise((resolve, reject) => {
            fetch(urlProd + "/process_payment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((response) => {
                    servicos.curso.update({
                        pagamento: response.id,
                        status: response.status === "approved" ? 'ATIVO' : 'PAGAMENTO',
                        dataPagamento: response.status === "approved" ? moment().format('YYYY-MM-DD HH:mm') : null,
                    }).then(() => {
                        if (response.status === "approved") {
                            servicos.cursos.doc(curso.idCurso).collection('aulas').get().then((res) => {
                                const data = res.docs.map((d) => d.data())
                                servicos.curso.update({
                                    totalAulas: data.length,
                                    aulaAtual: data.filter((d)=> d.posicao ===  1)[0].id
                                }).then(() => {
                                    window.paymentBrickController.unmount()
                                    window.location = '/mensagemPagamento?c=' + idCurso + '&p=' + response.id + '&u=' + idUsuario
                                })
                            }).then(() => {
                                window.paymentBrickController.unmount()
                                window.location = '/mensagemPagamento?c=' + idCurso + '&p=' + response.id + '&u=' + idUsuario
                            })
                        } else {
                            window.paymentBrickController.unmount()
                            window.location = '/mensagemPagamento?c=' + idCurso + '&p=' + response.id + '&u=' + idUsuario
                        }
                    })

                    setStatus(true)
                    resolve();
                })
                .catch((error) => {
                    console.log(error)
                    // lidar com a resposta de erro ao tentar criar o pagamento
                    reject();
                });
        });
    };

    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };
    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            <div>
                <section className="row page-header">
                    <div className="container">
                        <h4>Formas de Pagamento</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">Revise suas informações</h2>
                                </div>
                                <h4>Verifique o detalhe de seu curso</h4>
                                {curso.id ?
                                    <>
                                        <div className="featured-content">
                                            <div className="item"><img style={{ height: '100%', width: '100%' }} src={curso.imagem} alt="imagem" /></div>
                                        </div>

                                        <div className="row m0 event_title" style={{ marginTop: 20 }}>
                                            <h2 className="hhh h1 pull-left">Extrato</h2>
                                            {curso.desconto ?
                                                <h2 className="hhh h1 pull-right" style={{ color: '#D8D8D8', fontSize: 18, marginTop: 5, textDecoration: 'line-through' }}>{curso.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                                :
                                                null
                                            }
                                            {/* <h2 className="hhh h1 pull-right" style={{ color: '#125431', marginRight: 5 }}>{curso.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2> */}
                                        </div>
                                        <div className="table-responsive checkout-table">
                                            <table className="table">
                                                <thead>

                                                </thead>
                                                <tbody>
                                                    <tr className="alert" role="alert">
                                                        <td>Nome do Curso</td>
                                                        <td><b>{curso.titulo}</b></td>
                                                    </tr>
                                                    <tr className="alert" role="alert">
                                                        <td>Categoria</td>
                                                        <td><b>{curso.categoria}</b></td>
                                                    </tr>
                                                    <tr className="alert" role="alert">
                                                        <td>Total</td>
                                                        <td><b>R$ {curso.valor}</b></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={3} style={{ textAlign: 'center' }}>
                                                            <button onClick={() => criaPreferencia()} type="button" className="btn-primary">Realizar Pagamento</button>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </>
                                    :
                                    null
                                }
                                {pagamento ?
                                    <div className="row tabs-row shortcodeBlock m0" id="tabs">
                                        <h2 className="hhh h1 shortcodeName">Pagamento via Mercado Pago</h2>
                                        <p style={{ textAlign: 'justify' }}>Mercado Pago no site oferece medidas robustas de segurança, como criptografia de dados, proteção contra fraudes. Essas medidas visam garantir a segurança das transações realizadas na plataforma e proporcionar uma experiência confiável aos usuários. <a href='https://www.mercadopago.com.br/'>Clique aqui e conheça o mercado pago.</a></p>
                                        <Payment
                                            initialization={initialization}
                                            customization={customization}
                                            onSubmit={onSubmit}
                                            onReady={onReady}
                                            onError={onError}
                                        />
                                    </div>
                                    :
                                    null
                                }

                            </div>
                            <div className="col-md-4 sidebar post-sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8105242494"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="autorelaxed"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}