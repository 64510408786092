import React from 'react';
import Routes from './Routes'
import firebase from 'firebase'
import { initMercadoPago } from '@mercadopago/sdk-react';
initMercadoPago('TEST-08fd3de3-b5f7-4194-8d1b-5b9d2cedb6d5',{
  locale: 'pt'
});

const firebaseConfig = {
  apiKey: "AIzaSyD5MtDKYX62KAP93TjD8aQb6OhD6izsjlE",
  authDomain: "fabrica-de-sonhos-virtua-e8cae.firebaseapp.com",
  projectId: "fabrica-de-sonhos-virtua-e8cae",
  storageBucket: "fabrica-de-sonhos-virtua-e8cae.appspot.com",
  messagingSenderId: "69586516413",
  appId: "1:69586516413:web:838a7b6bf6c88ce152fad4"
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="wrapper">
      <Routes />
    </div>
  );
}

export default App;
