import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

import firebase from 'firebase';
import { Adsense } from '@ctrl/react-adsense';

export default function Projetos() {
    const [servicos] = useState({
        projetos: firebase.firestore().collection('projetos').where('status', '==', 'ATIVO')
    })

    const [projetos, setProjetos] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        servicos.projetos.get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setProjetos(data)
            setLoading(false)
        })
    }, [])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            {/*Featured Slider*/}
            <section className="row page-header-projetos">
                <div className="container">
                    <h4>Projetos</h4>
                </div>
            </section>


            <div className="page-wrapper row">
                <section className="row gallery-content">
                    <div className="container">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">projetos que mudam vidas</h6>
                            <h3>nos ajude a ajudar cada sonho virtual</h3>
                        </div>
                        <div className="row">
                            <div className="gallery_container row-style">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8704008291"
                                    style={{ display: 'block', height: 100 }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                            <div className="gallery_container row-style">
                                <div className="grid-sizer" />
                                {/*Item*/}
                                {projetos.map((projeto) =>
                                    <div key={projeto.id} className="col-sm-12 gallery-item education">
                                        <div className="media">
                                            <div className="media-left">
                                                <div className="featured-content">
                                                    <div className="item"><img src={projeto.miniatura} alt="imagem"style={{width:'100%',height:'100%'}}/></div>
                                                </div>
                                            </div>
                                            <div className="media-body" style={{padding:10}}>
                                                <h4 className="title"><a href={"/projeto?i="+projeto.id}>{projeto.titulo}</a></h4>
                                                <p>{projeto.descricao} </p>
                                                <a href={"/projeto?i="+projeto.id} className="btn-primary btn-outline">Quero mais detalhes</a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {projetos.length > 10 ?
                            <ul className="gallery-pagination list-unstyled">
                            <li className="prev"><a href={() => false}>prev</a></li>
                            <li className="page-no first-no active"><a href={() => false}>1</a></li>
                            <li className="page-no last-no"><a href={() => false}>2</a></li>
                            <li className="next"><a href={() => false}>next</a></li>
                        </ul>
                            :
                            null
                            }
                            
                        </div>
                    </div>
                </section>


                <Footer />
            </div>



        </>
    );
}