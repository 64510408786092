import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';
import firebase from 'firebase';

export default function Perfil() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        
        // servicos.auth.onAuthStateChanged((user) => {
        //     if (user) {
        //         // setLoading(false)
        //         // servicos.usuarios.doc(user.uid).get().then((res) => {
        //         //     setUsuario(res.data())
        //         //     console.log(res.data())
        //         // })
        //     }
        // })
    }, [])
    if(loading){
        return(
            <div className='loader-container'></div>
        )
    }
    

    return (
        <>
            
            <Header />
            
            <section className="row page-header-contato">
                <div className="container">
                    <h4>Meu Perfil</h4>
                </div>
            </section>
            {/* {usuario && usuario.id ?
                <section className="row team-content page-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row team_members_row">
                            <div className="col-sm-4">
                                <div className="row board_head m0">
                                    <img style={{ height: 449 }} src={usuario.avatar} alt className="img-responsive" />
                                    <div className="memberIntro row m0">
                                        <h3>{usuario.nome}</h3>
                                        <h6>{usuario.escola}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <h3 className="team_page_title">Dados Pessoais</h3>
                                <div className="row checkout-form" style={{marginTop:30}}>
                                    <div className='col-md-6' >
                                        <label>Nome</label>
                                        <input  type="text" className="form-control" placeholder="Seu nome" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Sobrenome</label>
                                        <input  type="text" className="form-control" placeholder="Seu Sobrenome" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>CPF</label>
                                        <input  type="text" className="form-control" placeholder="999.999.999-99" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>Celular</label>
                                        <input  type="text" className="form-control" placeholder="99 9999-9999" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Email</label>
                                        <input  type="text" className="form-control" placeholder="email@email.com" />
                                    </div>
                                </div>
                                <h3 className="team_page_title">Dados Endereço</h3>
                                <div className="row checkout-form" style={{marginTop:30}}>
                                    <div className='col-md-6' >
                                        <label>Nome</label>
                                        <input  type="text" className="form-control" placeholder="Seu nome" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Sobrenome</label>
                                        <input  type="text" className="form-control" placeholder="Seu Sobrenome" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>CPF</label>
                                        <input  type="text" className="form-control" placeholder="999.999.999-99" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>Celular</label>
                                        <input  type="text" className="form-control" placeholder="99 9999-9999" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Email</label>
                                        <input  type="text" className="form-control" placeholder="email@email.com" />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
                :
                null
            } */}

            <section class="row page-content" style={{ backgroundColor: 'white' }}>
                <div class="container">
                    <div class="row event-listing-row">
                        <div class="col-sm-6 col-md-4 event-listing">
                            <div>
                                <div className="images_row row m0">
                                    <a href="single-event.html"><img src="http://themedesigner.in.dimgx.net/demo/Helping-Hands/images/events/2.jpg" alt /></a>
                                </div>
                                <div className="event_excepts row m0">
                                    <h4 className="event_title"><a href="single-event.html">Meus dados</a></h4>
                                    <p>Visualize, edite ou verifique todos os seus dados Pessoais</p>
                                    <a href="/dados" className="btn-primary btn-outline">acessar meus dados</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 event-listing">
                            <div>
                                <div className="images_row row m0">
                                    <a href="single-event.html"><img src="http://themedesigner.in.dimgx.net/demo/Helping-Hands/images/events/2.jpg" alt /></a>
                                </div>
                                <div className="event_excepts row m0">
                                    <h4 className="event_title"><a href="single-event.html">Meus Cursos</a></h4>
                                    <p>Visualize seus cursos adquiridos, assim como estudar e assisti-los</p>
                                    <a href="/meuscursos" className="btn-primary btn-outline">acessar meus cursos</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 event-listing">
                            <div>
                                <div className="images_row row m0">
                                    <a href="single-event.html"><img src="http://themedesigner.in.dimgx.net/demo/Helping-Hands/images/events/2.jpg" alt /></a>
                                </div>
                                <div className="event_excepts row m0">
                                    <h4 className="event_title"><a href="single-event.html">Meus Chamados</a></h4>
                                    <p>Verifique seus chamados, abra um novo ou visualize atuais</p>
                                    <a href="/perfil" className="btn-primary btn-outline">acessar meus chamados</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="page-wrapper row">
                <Footer />
            </div>



        </>
    );
}