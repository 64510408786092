import React, { useState, useEffect } from 'react';
import { StatusScreen } from '@mercadopago/sdk-react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import firebase from 'firebase';
import { Adsense } from '@ctrl/react-adsense';

export default function Notificacao() {
    const queryParams = new URLSearchParams(window.location.search);
    const [idCurso] = useState(queryParams.get('c'))
    const [idUsuario] = useState(queryParams.get('u'))
    const [servicos] = useState({
        auth: firebase.auth(),
        cursos: firebase.firestore().collection('usuarios').doc(idUsuario).collection('cursos').doc(idCurso),
    })
    const [curso, setCurso] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        servicos.cursos.get().then((res) => {
            setCurso(res.data())
            setLoading(false)
        })
    }, [])

    // Payments
    const initializationStatus = {
        paymentId: queryParams.get('p'),
    };

    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };
    const onError = async (error) => {
        console.log(error);
    };
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            <div>

                <section className="row gallery-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 sidebar post-sidebar">
                                <StatusScreen
                                    initialization={initializationStatus}
                                    onReady={onReady}
                                    onError={onError}
                                />
                                {curso.id && curso.status !== 'ATIVO' ?
                                    <div className="row error_message" style={{ textAlign: 'center' }}>
                                        <div className="row m0 buttons" style={{ textAlign: 'center' }}>
                                            <a href="/cursos" className="btn-primary">Tente Novamente</a>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                            </div>
                            <div className="col-sm-8 single-project">
                                {curso.id && curso.status === 'ATIVO' ?
                                    <>
                                        <div className="featured-content">
                                            <div className="item"><img style={{ height: '100%', width: '100%' }} src={curso.imagem} alt="imagem" /></div>
                                        </div>

                                        <div className="row m0 event_title" style={{ marginTop: 20 }}>
                                            <h2 className="hhh h1 pull-left">{curso.titulo}</h2>

                                        </div>
                                        <h4>{curso.descricao} </h4>
                                        <div className="row m0 event_title" style={{ marginTop: 20 }}>
                                            <h4 className="hhh h1 pull-left">Como seguir agora?</h4>
                                        </div>
                                        <h4>Você receberá um email seguindo todas as instruções de como acessar seus cursos. </h4>
                                        <h4>Esperamos que você disfrute de todo o conteúdo proposto pelo curso e possa lhe direcionar para o melhor sempre! </h4>
                                        <h4>Qualquer dúvida, pode entrar em contato conosco para podermos lhe ajudar no que pudermos. </h4>
                                        <h4>Te encontro la,</h4>
                                        <h4><b>Equipe Fábrica de Sonhos Virtuais</b></h4>
                                        <div className="row error_message" >
                                            <div className="row m0 buttons" >
                                                <a href="/meuscursos" className="btn-primary">Acessar meus Cursos</a>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }

                            </div>
                            <div className="col-md-4 sidebar post-sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8105242494"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="autorelaxed"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}