import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Footer from '../componentes/Footer';

import firebase from 'firebase';
import moment from 'moment';
import { Adsense } from '@ctrl/react-adsense';
import Swal from 'sweetalert2';

export default function Home() {
    const [servicos] = useState({
        blog: firebase.firestore().collection('blog').where('status', '==', 'ATIVO'),
        projetos: firebase.firestore().collection('projetos').where('status', '==', 'ATIVO'),
    })
    const [blog, setBlog] = useState([])
    const [projetos, setProjetos] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        servicos.blog.get().then((res) => {
            setLoading(false)
            const data = res.docs.map((d) => d.data())
            setBlog(data)
        })
        servicos.projetos.get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setProjetos(data)
        })
    }, [])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>

            <Header />
            {/*Featured Slider*/}
            <section className="row featured_news">
                <Carousel
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={5000}
                >
                    <div className="item">
                        <img src="/images/slide.png" alt="imagem" />
                        <div className="row caption m0">
                            <div className="container">
                                <div className="volunteer_summery_box col-lg-5 col-md-10">
                                    <h3 className="news-title"><a href="single.html">Projeto Escola Fábrica de Sonhos Virtuais</a></h3>
                                    <p>Levando a tecnologia acessível a todos os sonhos do nosso futuro
                                    </p>
                                    <a href="single.html" className="btn-primary">Saiba Mais</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </Carousel>


            </section>

            <div className="page-wrapper row">

                <section className="how_help row sectionGap">
                    <div className="container">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">conheça nosso projeto</h6>
                            <h3>Social, educacional, mudança de vida e muito aprendizado em tecnologia</h3>
                        </div>
                        <div className="row help-process_row">
                            <div className="col-sm-3 help-process">
                                <div className="icon_box row m0">
                                    <img src="images/sobre/simples.png" alt="imagem" />
                                </div>
                                <h5 style={{ color: '#125431' }}>Simples</h5>
                                <p style={{ color: '#125431' }}>Aprenda do básico ao avançado com linguagem simplicficada do mundo tecnológico</p>
                            </div>
                            <div className="col-sm-3 help-process">
                                <div className="icon_box row m0">
                                    <img src="images/sobre/pratico.png" alt="imagem" />
                                </div>
                                <h5 style={{ color: '#dd971b' }}>prático</h5>
                                <p style={{ color: '#dd971b' }}>Aulas didáticas, práticas e com conteúdo rico em tecnologia em uma plataforma só</p>
                            </div>
                            <div className="col-sm-3 help-process">
                                <div className="icon_box row m0">
                                    <img src="images/sobre/acessivel.png" alt="imagem" />
                                </div>
                                <h5 style={{ color: '#BC331D' }}>acessível</h5>
                                <p style={{ color: '#BC331D' }}>Conexão básica a internet, um celular e poucos cliques se cria um mundo mágico</p>
                            </div>
                            <div className="col-sm-3 help-process">
                                <div className="icon_box row m0">
                                    <img src="images/sobre/inovador.png" alt="imagem" />
                                </div>
                                <h5 style={{ color: '#3598F4' }}>inovador</h5>
                                <p style={{ color: '#3598F4' }}>Aperfeçoe seus conhecimentos para gerar renda pra toda família e encaminhar seu sonho</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row sponsor_banner text-center style2">
                    <div className="container" style={{ height: 200 }}>

                    </div>
                </section>
                <section style={{ width: '100%', height: 200, textAlign: 'center' }}>
                    <Adsense
                        client="ca-pub-5658260251546452"
                        slot="8704008291"
                        style={{ display: 'block', height: 100 }}
                        layout="in-article"
                        format="auto"
                        responsive={true}
                    />
                </section>

                <section className="row upcoming_recent_events style2">
                    <div className="container">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">Fique por dentro</h6>
                            <h3>Projetos que mudam vidas</h3>
                        </div>
                        {projetos.length > 0 ?
                            <div className="row">

                                {projetos.map((projeto) =>
                                    <div key={projeto.id} className="col-sm-12 col-md-6 upcoming_events">
                                        <div className="row event_cover_photo">
                                            <img src={projeto.miniatura} alt="imagem" className="img-responsive" />
                                            <div className="upcoming_label label">destaque</div>
                                            <h6 className="event_time_loc">
                                                <span className="by">{projeto.responsavel}</span>
                                                <span className="date_time">{moment(projeto.dataCadastro).format('LLL')}</span>
                                            </h6>
                                        </div>
                                        <h4 className="event_title"><a href={"/projeto?i=" + projeto.id}>{projeto.titulo}</a>
                                        </h4>
                                        <p className="event_summery" style={{ textAlign: 'justify' }}>{projeto.descricao}</p>
                                    </div>
                                )}
                            </div>
                            :
                            <div className="row">
                                {projetos.length > 0 ?
                                    <div className="col-sm-12 col-md-6 upcoming_events">
                                        <div className="row event_cover_photo">
                                            <img src={projetos[0].miniatura} alt="imagem" className="img-responsive" />
                                            <div className="upcoming_label label">destaque</div>
                                            <h6 className="event_time_loc">
                                                <span className="by">{projetos[0].responsavel}</span>
                                                <span className="date_time">{moment(projetos[0].dataCadastro).format('LLL')}</span>
                                            </h6>
                                        </div>
                                        <h4 className="event_title"><a href={"/projeto?i=" + projetos[0].id}>{projetos[0].titulo}</a>
                                        </h4>
                                        <p className="event_summery" style={{ textAlign: 'justify' }}>{projetos[0].descricao}</p>
                                    </div>
                                    :
                                    null
                                }

                                {projetos.filter((p) => p.id !== projetos[0].id).map((projeto) =>
                                    <div key={projeto.id} className="col-sm-6 col-md-3 upcoming_events">
                                        <div className="row event_cover_photo">
                                            <img src={projeto.miniatura} alt="imagem" className="img-responsive" />
                                            <h6 className="event_time_loc">
                                                <span className="date_time">{moment(projeto.dataCadastro).format('LLL')}</span>
                                            </h6>
                                        </div>
                                        <h5 className="event_title"><a href="single-event.html">{projeto.titulo}</a></h5>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </section>
                <section className="row quotes_row style2">
                    <div className="container">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">Veja quem já está conosco</h6>
                            <h3>Depoimentos &amp; de quem já chegou longe..</h3>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 quotation_block text-center">
                                <div className="quote_block row">
                                    <span className="quote_sign">“</span>
                                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit. Nam malesuada dapi bus diam, ut
                                        fringilla purus efficitur eget. Morbi sagittis mi ac eros semper pharetra. Praesent sed.
                                    </p>
                                </div>
                                <h5>Andy Dufresne</h5>
                                <h6>Appstore</h6>
                            </div>
                            <div className="col-sm-4 quotation_block text-center">
                                <div className="quote_block row">
                                    <span className="quote_sign">“</span>
                                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit. Nam malesuada dapi bus diam, ut
                                        fringilla purus efficitur eget. Morbi sagittis mi ac eros semper pharetra. Praesent sed.
                                    </p>
                                </div>
                                <h5>John Doe</h5>
                                <h6>Company Name</h6>
                            </div>
                            <div className="col-sm-4 quotation_block text-center">
                                <div className="quote_block row">
                                    <span className="quote_sign">“</span>
                                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit. Nam malesuada dapi bus diam, ut
                                        fringilla purus efficitur eget. Morbi sagittis mi ac eros semper pharetra. Praesent sed.
                                    </p>
                                </div>
                                <h5>James Flintoff</h5>
                                <h6>Designer</h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row beVolunteer text-center">
                    <div className="container beVolunteerBox">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">que legal..</h6>
                            <h3>quer fazer parte ?</h3>
                        </div>
                        <p>Clique no botão abaixo e faça parte deste mundo de tecnologia</p>
                        <a href='/cursos' className="btn-primary">estudar agora</a>
                    </div>
                </section>
                <section className="row latest_news">
                    <div className="container">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">novidades</h6>
                            <h3>Fique por dentro do nosso blog</h3>
                        </div>
                        <div className="row">
                            {blog.map((blog) =>
                                <div key={blog.id} className="latest-post col-md-3 col-sm-6">
                                    <div className="row m0 featured_cont">
                                        <img style={{ width: '100%', height: 150 }} src={blog.miniatura} alt="imagem" className="img-responsive" />
                                        <i className="fa fa-youtube-play" />
                                    </div>
                                    <h5 className="post-title"><a href={'/post?i=' + blog.id}>{blog.titulo}</a></h5>
                                    <h6 className="post-meta"><a href={'/post?i=' + blog.id}>{blog.usuario.nome}</a><a href={'/post?i=' + blog.id}>{moment(blog.dataCadastro).format('DD/MM/YYYY')}</a></h6>
                                    <div className="post-excerpts row m0">{blog.descricao}</div>
                                    <a href={'/post?i=' + blog.id} className="btn-primary btn-outline">quero ler</a>
                                </div>
                            )}
                        </div>
                        <div className="row sectionTitle text-center">
                            <a style={{ width: '50%', textAlign: 'center' }} href="/blog" className="btn-primary btn-outline">Conhecer Mais</a>
                        </div>

                    </div>

                </section>
                <Footer />
            </div>



        </>
    );
}