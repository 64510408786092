const baseUrl = "https://api.mercadopago.com"
const token = "TEST-3492540133896457-022302-22070318998a0837cd4bab1a8effb387-145975414"

export const geraPreferencia = (dados,device_id) => {
    return (async () => {
        try {
            const URL = baseUrl + "/checkout/preferences";
            var headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token)
            // headers.append('X-meli-session-id', device_id)
            headers.append('x-integrator-id', "dev_24c65fb163bf11ea96500242ac130004")
            headers.append("Content-Type", "application/json")
            const response = await fetch(URL,
                {
                    headers: headers,
                    method: "post",
                    body: JSON.stringify(dados)
                }
            )
            const data = await response.json()
            console.log(data)
            return data;
        } catch {
            return 2
        }
    })();
}

export const getPagamento = (id) => {
    return (async () => {
        try {
            const URL = baseUrl + "/v1/payments/" + id;
            var headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token)
            headers.append("Content-Type", "application/json")
            const response = await fetch(URL,
                {
                    method: 'GET',
                    headers: headers,
                }
            )
            const data = await response.json()
            return data;
        } catch (erro){
            return erro
        }
    })();

}
