import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

import firebase from 'firebase';
import { Adsense } from '@ctrl/react-adsense';

export default function Cursos() {
    const [servicos] = useState({
        cursos: firebase.firestore().collection('cursos').where('status', '==', 'ATIVO')
    })
    const [categoria, setCategoria] = useState('all')
    const [cursos, setCursos] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        servicos.cursos.get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setCursos(data)
            setLoading(false)
        })
    }, [])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            {/*Featured Slider*/}
            <section className="row page-header-cursos">
                <div className="container">
                    <h4>blog news</h4>
                </div>
            </section>


            <div className="page-wrapper row">
                <section className="row gallery-content">
                    <div className="container">
                        <div className="row sectionTitle text-center">
                            <h6 className="label label-default">how you could help</h6>
                            <h3>WE NEED YOUR HELP TO HELP OTHERS, SEE OUR CAUSES gallery</h3>
                        </div>
                        <div className="row m0 filters_row">
                            <ul className="gallery-filter nav pull-left">
                                {categoria === 'all' ?
                                    <li className="active">TODOS</li>
                                    :
                                    <li  >TODOS</li>
                                }
                                {categoria === 'PROGRAMACAO' ?
                                    <li className='active'>PROGRAMAÇÃO</li>
                                    :
                                    <li  >PROGRAMAÇÃO</li>
                                }
                                {categoria === 'COMPUTADORES' ?
                                    <li className='active'>MANU. COMPUTADORES</li>
                                    :
                                    <li >MANU. COMPUTADORES</li>
                                }
                                {categoria === 'CELULARES' ?
                                    <li className='active'>MANU. CELULARES</li>
                                    :
                                    <li >MANU. CELULARES</li>
                                }
                                {categoria === 'DOCUMENTOS' ?
                                    <li className='active'>DOCUMENTOS</li>
                                    :
                                    <li >DOCUMENTOS</li>
                                }
                                {categoria === 'SISTEMAS' ?
                                    <li className='active'>SISTEMAS OPERACIONAIS</li>
                                    :
                                    <li >SISTEMAS OPERACIONAIS</li>
                                }
                            </ul>
                        </div>
                        <div className="row">
                            <div className="gallery_container row-style">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8704008291"
                                    style={{ display: 'block', height: 100 }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                            <div className="gallery_container row-style">
                                <div className="grid-sizer" />
                                {cursos.map((curso) =>
                                    <div key={curso.id} className="col-sm-12 gallery-item education">
                                        <div className="media">
                                            <div className="media-left">
                                                <a href={"/curso/" + curso.slug}><img style={{ height: 200, width: 260 }} src={curso.miniatura} alt="imagem" /></a>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="title"><a href={"/curso/" + curso.slug}>{curso.titulo}</a></h4>
                                                <p>{curso.descricao}</p>
                                                <a href={"/curso/" + curso.slug} className="btn-primary btn-outline">conhecer o curso</a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <ul className="gallery-pagination list-unstyled">
                                <li className="prev"><a href={() => false}>prev</a></li>
                                <li className="page-no first-no active"><a href={() => false}>1</a></li>
                                <li className="page-no last-no"><a href={() => false}>2</a></li>
                                <li className="next"><a href={() => false}>next</a></li>
                            </ul>
                        </div>
                    </div>
                </section>


                <Footer />
            </div>



        </>
    );
}