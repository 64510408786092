import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';

export default function Termos() {
    return (
        <>
            <Header />
            {/*Featured Slider*/}

            <div>
                <section className="row page-header">
                    <div className="container">
                        <h4>Termos de Uso</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{backgroundColor:'white'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="row m0 " style={{marginTop:20}}>
                                    <h2 className="hhh">Termos de Uso - Plataforma de Cursos Online do Projeto Escola Fábrica de Sonhos Virtuais</h2>
                                   
                                </div>
                                <h4 style={{marginTop:20,textAlign:'justify'}}>Estes Termos de Uso ("Termos") estabelecem os direitos e obrigações entre você (doravante referido como "Usuário") e o Projeto Escola Fábrica de Sonhos Virtuais (doravante referido como "Projeto"), ao utilizar a plataforma de cursos online fornecida pelo Projeto Escola Fábrica de Sonhos Virtuais (doravante referida como "Plataforma"). Ao acessar e utilizar a Plataforma, você concorda em cumprir estes Termos em sua totalidade. Caso não concorde com estes Termos, solicitamos que não utilize a Plataforma.</h4>
                                <h4><b>1. Conteúdo da Plataforma</b></h4>
                                <p style={{textAlign:'justify'}}>1.1 O Projeto Escola Fábrica de Sonhos Virtuais disponibiliza cursos online através da Plataforma, os quais podem incluir vídeos, materiais de estudo, questionários, fóruns de discussão e outros recursos relacionados ao aprendizado.</p>
                                <p style={{textAlign:'justify'}}>1.2 O conteúdo fornecido na Plataforma é protegido por direitos autorais e propriedade intelectual, sendo exclusivamente destinado ao uso pessoal e não comercial do Usuário. É proibido copiar, reproduzir, distribuir ou utilizar o conteúdo da Plataforma sem autorização prévia por escrito do Projeto.</p>
                                <h4><b>2. Inscrição e Acesso</b></h4>
                                <p style={{textAlign:'justify'}}>2.1 Para utilizar a Plataforma, o Usuário deve se inscrever, fornecendo informações precisas, atualizadas e completas durante o processo de registro. É responsabilidade do Usuário manter suas informações de login seguras e atualizadas.</p>
                                <p style={{textAlign:'justify'}}>2.2 O Projeto se reserva o direito de recusar ou cancelar a inscrição do Usuário na Plataforma, a seu exclusivo critério, sem a obrigação de fornecer uma justificativa.</p>
                                <h4><b>3. Responsabilidades do Usuário</b></h4>
                                <p style={{textAlign:'justify'}}>3.1 O Usuário é responsável por manter a confidencialidade de suas informações de login e por todas as atividades realizadas em sua conta na Plataforma.</p>
                                <p style={{textAlign:'justify'}}>3.2 O Usuário concorda em utilizar a Plataforma de forma legal, ética e de acordo com estes Termos.</p>
                                <p style={{textAlign:'justify'}}><b>3.3 É proibido ao Usuário:</b></p>
                                <p style={{textAlign:'justify'}}>a) Utilizar a Plataforma para qualquer finalidade ilegal, fraudulenta, difamatória, obscena, ofensiva ou prejudicial;</p>
                                <p style={{textAlign:'justify'}}>b) Violar os direitos de propriedade intelectual do Projeto ou de terceiros;</p>
                                <p style={{textAlign:'justify'}}>c) Transmitir vírus, malware ou qualquer outro código prejudicial pela Plataforma;</p>
                                <p style={{textAlign:'justify'}}>d) Interferir ou prejudicar a funcionalidade da Plataforma ou dos servidores do Projeto;</p>
                                <p style={{textAlign:'justify'}}>e) Acessar, coletar ou armazenar dados pessoais de outros usuários sem autorização.</p>
                                <h4><b>4. Pagamento e Cancelamento</b></h4>
                                <p style={{textAlign:'justify'}}>4.1 Alguns cursos disponíveis na Plataforma podem ser pagos, e o Usuário deverá seguir as instruções de pagamento fornecidas pelo Projeto para ter acesso a esses cursos.</p>
                                <p style={{textAlign:'justify'}}>4.2 O Projeto reserva-se o direito de alterar os preços dos cursos a qualquer momento, mas compromete-se a fornecer um aviso prévio ao Usuário sobre tais alterações.</p>
                                <p style={{textAlign:'justify'}}>4.3 O Usuário pode solicitar o cancelamento de sua inscrição em cursos pagos de acordo com a política de cancelamento fornecida pelo Projeto. O cancelamento pode implicar em perda de acesso ao conteúdo do curso e a política de reembolso será determinada pelo Projeto.</p>
                                <h4><b>5. Limitação de Responsabilidade</b></h4>
                                <p style={{textAlign:'justify'}}>5.1 O Projeto Escola Fábrica de Sonhos Virtuais não será responsável por quaisquer danos diretos, indiretos, incidentais, consequenciais ou especiais resultantes do uso ou impossibilidade de uso da Plataforma, incluindo perda de dados, interrupção do negócio ou danos à reputação.</p>
                                <p style={{textAlign:'justify'}}>5.2 O Projeto não garante a disponibilidade contínua e ininterrupta da Plataforma, podendo ocorrer interrupções para manutenção ou por eventos fora do controle do Projeto.</p>
                                <h4><b>6. Alterações nos Termos</b></h4>
                                <p style={{textAlign:'justify'}}>6.1 O Projeto Escola Fábrica de Sonhos Virtuais pode modificar estes Termos a qualquer momento, mediante aviso prévio aos Usuários.</p>
                                <p style={{textAlign:'justify'}}>6.2 O uso contínuo da Plataforma após a modificação dos Termos constitui a aceitação dos novos termos por parte do Usuário.</p>
                                <h4><b>7. Disposições Gerais</b></h4>
                                <p style={{textAlign:'justify'}}>7.1 Estes Termos são regidos pelas leis do Brasil. Qualquer disputa decorrente ou relacionada a estes Termos será submetida à jurisdição exclusiva dos tribunais brasileiros.</p>
                                <p style={{textAlign:'justify'}}>7.2 Caso qualquer disposição destes Termos seja considerada inválida ou inexequível por um tribunal competente, as demais disposições permanecerão em pleno vigor e efeito.</p>
                                <p style={{textAlign:'justify'}}>7.3 A falha do Projeto em exercer ou fazer cumprir qualquer direito ou disposição destes Termos não constituirá uma renúncia a tal direito ou disposição.</p>
                                <div className="text-block row">Ao utilizar a Plataforma, o Usuário reconhece ter lido, compreendido e concordado com estes Termos de Uso.</div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <div className="row m0 widget widget-similar-project widget-similar">
                                    <div className="similar-project">
                                       
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href="/contato">Dúvidas?</a></h4>
                                                <p>Para mais esclarecimentos sobre os termos de uso da plataforma, entre em contato com conosco que tiramos todas as dúvidas sobre o Projeto</p>
                                                <a href="/contato" className="btn-primary btn-outline">fale conosco</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
            </div>

        </>
    );
}