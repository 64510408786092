import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// Site
import Home from './pages/Home'
import Sobre from './pages/Sobre'
import SobreCursos from './pages/SobreCursos'
import SobreParceiros from './pages/SobreParceiros'
import Contato from './pages/Contato'
import Parceiros from './pages/Parceiros'
import Page404 from './pages/404'
import Notificacao from './pages/Notificacao'
import Cadastro from './pages/Cadastro'
import Login from './pages/Login'

// Aluno
import Perfil from './pages/Aluno/Perfil'
import Dados from './pages/Aluno/Dados'
import MeusCursos from './pages/Aluno/Cursos'
import MeusCurso from './pages/Aluno/Cursos/MeuCurso'

// Blog
import Blog from './pages/Blog'
import Post from './pages/Blog/Post'

// Projetos
import Projetos from './pages/Projetos'
import Projeto from './pages/Projetos/Projeto'

// Cursos
import Cursos from './pages/Cursos'
import Curso from './pages/Cursos/Curso'
import Checkout from './pages/Checkout'
import MensagemPagamento from './pages/MensagemPagamento'

//Doação 
import Doacao from './pages/Doacao'

// Legal
import Termos from './pages/Legal/Termos'
import Privacidade from './pages/Legal/Privacidade'



export default class Routes extends Component {
    render() {
        return (
            <div >
                <BrowserRouter>
                    <Switch>
                        {/* Site */}
                        <Route path="/" exact={true} component={Home} />
                        <Route path="/sobre" exact={true} component={Sobre} />
                        <Route path="/sobreCursos" exact={true} component={SobreCursos} />
                        <Route path="/sobreParceiros" exact={true} component={SobreParceiros} />
                        <Route path="/contato" exact={true} component={Contato} />
                        <Route path="/sejaParceiro" exact={true} component={Parceiros} />
                        <Route path="/cadastro" exact={true} component={Cadastro} />
                        <Route path="/login" exact={true} component={Login} />
                        
                        {/* Aluno */}
                        <Route path="/perfil" exact={true} component={Perfil} />
                        <Route path="/dados" exact={true} component={Dados} />
                        <Route path="/meuscursos" exact={true} component={MeusCursos} />
                        <Route path="/meucurso" exact={true} component={MeusCurso} />

                        {/* Blog */}
                        <Route path="/blog" exact={true} component={Blog} />
                        <Route path="/post" exact={true} component={Post} />

                        {/* Blog */}
                        <Route path="/projetos" exact={true} component={Projetos} />
                        <Route path="/projeto" exact={true} component={Projeto} />

                        {/* Cursos */}
                        <Route path="/cursos" exact={true} component={Cursos} />
                        <Route path="/curso/:slug" exact={true} component={Curso} />
                        <Route path="/checkout" exact={true} component={Checkout} />
                        <Route path="/mensagemPagamento" exact={true} component={MensagemPagamento} />

                        {/* Doação */}
                        <Route path="/doacao" exact={true} component={Doacao} />

                        {/* Legal */}
                        <Route path="/termos" exact={true} component={Termos} />
                        <Route path="/privacidade" exact={true} component={Privacidade} />

                        
                        {/* Pages */}
                        <Route path="/notificacao" exact={true} component={Notificacao} />
                        <Route path="*">
                            <Page404 />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}