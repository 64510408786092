import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import firebase from 'firebase';
import Swal from 'sweetalert2'

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        mail: firebase.firestore().collection('mail'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    function realizaLogin() {
        if (!email) {
            mensagemAlerta('Por favor o email não pode ser vazio ou no formato inválido')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite sua senha')
            return
        }
        mensagemLoading('Realizando login...')
        servicos.auth.signInWithEmailAndPassword(email, senha).then((res) => {
            servicos.usuarios.doc(res.user.uid).get().then((snap) => {
                if (snap.data().usuario === 'ALUNO') {
                    mensagemSucesso('Login realizado com sucesso! Você será redirecionado a página principal')
                } else {
                    Swal.close();
                    mensagemAlerta('Usuário não autorizado para este ambiente.')
                    firebase.auth().signOut();
                }
            })
        }).catch((error) => {
            console.log(error)
            var errorCode = error.code;
            if (error) {
                if (errorCode === 'auth/invalid-email') {
                    Swal.close()
                    mensagemAlerta('Email incompleto ou não é válido')
                } else if (errorCode === 'auth/user-not-found') {
                    Swal.close()
                    mensagemAlerta('Usuário não cadastrado! Cadastre-se ou verifique novamente')
                } else if (errorCode === 'auth/wrong-password') {
                    Swal.close()
                    mensagemAlerta('Senha Incorreta')
                }
            }
        });

    }

    function sair() {
        servicos.auth.signOut().then(() => {
            window.location = '/'
        })
    }

    // Inputs
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/';
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <Header />
            <section className="row page-header">
                <div className="container">

                    <h4>Login</h4>
                </div>
            </section>

            <section className="row contact-content page-content" style={{ backgroundColor: 'white' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 contact-sidebar">
                            <img src="/images/sobre/sobre.png" style={{ width: '100%', height: '100%' }} alt="imagem" />
                        </div>
                        <div className="col-md-4">
                            <h3 className="contact_page_title" style={{ marginBottom: 10 }}>Login</h3>
                            <p style={{ fontSize: 18 }}>Realize login ou cadastre-se para se inscrever nos cursos</p>
                            <div className="row checkout-form " >
                                <div className='col-md-12' style={{ marginBottom: -20, marginTop: 20 }}>
                                    <label>Email</label>
                                    <input value={email} onChange={handleEmail} type="text" className="form-control" placeholder="Email" />
                                </div>
                                <div className='col-md-12' >
                                    <label>Senha</label>
                                    <input value={senha} onChange={handleSenha} type="password" className="form-control" placeholder="Senha" />
                                </div>
                                <div className='col-md-12' >
                                    <button style={{ width: '100%' }} onClick={() => realizaLogin()} type="button" defaultValue="Verificar" className="btn-primary">Login</button>
                                </div>
                                <div className='col-md-12' style={{ textAlign: 'center', marginTop: 20 }}>
                                    <a href='' style={{ fontSize: 13, color: 'grey' }}>Não possui login? Cadastre-se</a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>


            <div className="page-wrapper row">


                <Footer />
            </div>



        </>
    );
}