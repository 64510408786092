import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { buscaEndereco } from '../../../services/WsConnect';

export default function Dados() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})
    const [loading,setLoading] = useState(true)

    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [cpf, setCpf] = useState('')
    const [celular, setCelular] = useState('')
    const [email, setEmail] = useState('')
    const [pai, setPai] = useState('')
    const [mae, setMae] = useState('')

    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [referencia, setReferencia] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    const [escola, setEscola] = useState('')
    const [serie, setSerie] = useState('')
    const [turno, setTurno] = useState('')
    const [qtdAlunos, setQtdAlunos] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                    setNome(res.data().nome)
                    setSobrenome(res.data().sobrenome)
                    setCpf(res.data().cpf)
                    setCelular(res.data().celular)
                    setEmail(res.data().email)
                    setMae(res.data().mae)
                    setPai(res.data().pai)
                    setCep(res.data().endereco.cep)
                    setEndereco(res.data().endereco.endereco)
                    setBairro(res.data().endereco.bairro)
                    setNumero(res.data().endereco.numero)
                    setComplemento(res.data().endereco.complemento)
                    setReferencia(res.data().endereco.referencia)
                    setUf(res.data().endereco.uf)
                    setCidade(res.data().endereco.cidade)
                    setEscola(res.data().escola)
                    setSerie(res.data().serie)
                    setTurno(res.data().turno)
                    setQtdAlunos(res.data().qtdAlunos)
                    setLoading(false)
                })
            }
        })
    }, [])

    function atualizaDados() {
        if (!nome) {
            mensagemAlerta('Favor digite seu nome')
            return
        }
        if (!sobrenome) {
            mensagemAlerta('Favor digite seu sobrenome')
            return
        }
        if (!celular || celular.length < 11) {
            mensagemAlerta('O celular deve ter 11 caracteres, adicionando o DDD e o 9 na frente')
            return
        }
        if (!cep) {
            mensagemAlerta('Favor digite seu CEP')
            return
        }
        if (!endereco) {
            mensagemAlerta('Favor digite seu Endereço')
            return
        }
        if (!bairro) {
            mensagemAlerta('Favor digite seu bairro')
            return
        }
        if (!numero) {
            mensagemAlerta('Favor digite seu numero')
            return
        }
        mensagemLoading('Atualizando dados..')
        servicos.usuarios.doc(usuario.id).update({
            nome: nome,
            sobrenome: sobrenome,
            celular: celular,
            endereco: {
                cep: cep,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                complemento: complemento,
                referencia: referencia,
                cidade: cidade,
                uf: uf
            }
        }).then(() => {
            Swal.close();
            mensagemSucesso('Dados atualizado com sucesso!')
        })
    }

    // Inputs
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaEndereco(event.target.value).then((res) => {
                if (res.erro) {
                    mensagemErro('Endereço não localizado')
                    setEndereco('')
                    setBairro('')
                    setUf('')
                    setCidade('')
                } else {
                    setEndereco(res.logradouro)
                    setBairro(res.bairro)
                    setUf(res.uf)
                    setCidade(res.localidade)
                }
            })
        } else {
            setEndereco('')
            setBairro('')
            setUf('')
            setCidade('')
            setComplemento('')
            setReferencia('')
            setUf('')
            setCidade('')
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }
    const handleEscola = (event) => {
        event.persist();
        setEscola(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/';
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function sair(){
        servicos.auth.signOut().then(()=>{
            window.location = '/'
        })
    }

    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }

    return (
        <>
            <Header />
            <section className="row page-header-contato">
                <div className="container">
                    <h4>Meus Dados</h4>
                </div>
            </section>
            {usuario && usuario.id ?
                <section className="row team-content page-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row team_members_row">
                            <div className="col-sm-4">
                                <div className="row board_head m0">
                                    <img style={{ height: 449 }} src={usuario.avatar} alt='' className="img-responsive" />
                                    <div className="memberIntro row m0">
                                        <h6>Clique aqui Trocar minha imagem do perfil</h6>
                                        <input type='file' accept='.png, .jpeg, .jpg' style={{marginTop:10}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <h3 className="team_page_title">Dados Pessoais</h3>
                                <div className="row checkout-form" style={{ marginTop: 30 }}>
                                    <div className='col-md-6' >
                                        <label>Nome</label>
                                        <input value={nome} onChange={handleNome} type="text" className="form-control" placeholder="Seu nome" />

                                    </div>
                                    <div className='col-md-6' >
                                        <label>Sobrenome</label>
                                        <input value={sobrenome} onChange={handleSobrenome} type="text" className="form-control" placeholder="Seu Sobrenome" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>CPF <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <input disabled value={cpf} type="text" className="form-control" placeholder="999.999.999-99" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>Celular</label>
                                        <input value={celular} onChange={handleCelular} type="text" className="form-control" placeholder="99 9999-9999" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Email <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <input disabled value={email} type="text" className="form-control" placeholder="email@email.com" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Nome da Mãe <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <input disabled value={mae} type="text" className="form-control" placeholder="Nome da sua Mãe" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Nome do Pai <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <input disabled value={pai} type="text" className="form-control" placeholder="Nome do seu pai" />
                                    </div>
                                </div>
                                <h3 className="team_page_title">Dados Endereço</h3>
                                <div className="row checkout-form" style={{ marginTop: 30 }}>
                                    <div className='col-md-2' >
                                        <label>CEP</label>
                                        <input value={cep} onChange={handleCep} type="text" className="form-control" placeholder="66666666" />
                                    </div>
                                    <div className='col-md-4' >
                                        <label>Endereço </label>
                                        <input value={endereco} onChange={handleEndereco} type="text" className="form-control" placeholder="Rua, Casa, Residencial" />
                                    </div>
                                    <div className='col-md-4' >
                                        <label>Bairro</label>
                                        <input value={bairro} onChange={handleBairro} type="text" className="form-control" placeholder="Bairro" />
                                    </div>
                                    <div className='col-md-2' >
                                        <label>Número</label>
                                        <input value={numero} onChange={handleNumero} type="text" className="form-control" placeholder="Número" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Complemento</label>
                                        <input value={complemento} onChange={handleComplemento} type="text" className="form-control" placeholder="Bloco, Codominio" />
                                    </div>
                                    <div className='col-md-6' >
                                        <label>Referência</label>
                                        <input value={referencia} onChange={handleReferencia} type="text" className="form-control" placeholder="próximo..." />
                                    </div>
                                    <div className='col-md-2' >
                                        <label>UF</label>
                                        <input value={uf} o type="text" className="form-control" placeholder="UF" />
                                    </div>
                                    <div className='col-md-10' >
                                        <label>Cidade</label>
                                        <input disabled value={cidade} type="text" className="form-control" placeholder="Cidade" />
                                    </div>

                                </div>
                                <h3 className="team_page_title">Dados Escolares</h3>
                                <div className="row checkout-form" style={{ marginTop: 30 }}>
                                    <div className='col-md-6' >
                                        <label>Sua Escola <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <input disabled value={escola} type="text" className="form-control" placeholder="66666666" />
                                    </div>
                                    <div className='col-md-3' >
                                        <label>Série <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <select disabled name="country" id="country" className="form-control" value={serie}>
                                            <option >Selecione</option>
                                            <option selected={serie === '1' ? 'selected' : null} value={'1'}>1 Ano </option>
                                            <option selected={serie === '2' ? 'selected' : null} value={'2'}>2 ano </option>
                                            <option selected={serie === '3' ? 'selected' : null} value={'3'}>Terceirão</option>
                                        </select>
                                    </div>
                                    <div className='col-md-3' >
                                        <label>Turno <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <select disabled name="country" id="country" className="form-control" value={turno}>
                                            <option >Selecione</option>
                                            <option selected={turno === 'MATUTINO' ? 'selected' : null} value={'MATUTINO'}>Matutino</option>
                                            <option selected={turno === 'VESPERTINO' ? 'selected' : null} value={'VESPERTINO'}>Vespertino</option>
                                            <option selected={turno === 'NOTURNO' ? 'selected' : null} value={'NOTURNO'}>Noturno</option>
                                        </select>
                                    </div>
                                    <div className='col-md-3' >
                                        <label>Qtd Alunos <i style={{ color: '#BC331D' }} className="fa fa-ban" aria-hidden="true"></i></label>
                                        <select disabled name="country" id="country" className="form-control" value={qtdAlunos}>
                                            <option >Selecione</option>
                                            <option selected={qtdAlunos === '1' ? 'selected' : null} value={'1'}>De 10 a 20</option>
                                            <option selected={qtdAlunos === '2' ? 'selected' : null} value={'2'}>De 20 a 40</option>
                                            <option selected={qtdAlunos === '3' ? 'selected' : null} value={'3'}>De 40 em diante</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row checkout-form" style={{ marginTop: 30 }}>
                                    <div className='col-md-12' >
                                        <label><b>OBS</b></label>
                                        <p>Campos com o ícone <i style={{ color: 'red' }} className="fa fa-ban" aria-hidden="true"></i> devem ser aberto chamado para serem alterados. A mudança ocorre em até 24h</p>
                                    </div>

                                </div>

                                <div className="row checkout-form" style={{ marginTop: 30 }}>
                                    <div className='col-md-6' >
                                        <button onClick={() => atualizaDados()} style={{ width: '100%' }} type="button" defaultValue="Verificar" className="btn-primary">Salvar Informações</button>
                                    </div>
                                    <div className='col-md-6' >
                                        <button onClick={() => sair()}  style={{ width: '100%', backgroundColor: '#BC331D' }} type="button" defaultValue="Verificar" className="btn-primary">Sair da minha Conta</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
                :
                null
            }


            <div className="page-wrapper row">
                <Footer />
            </div>



        </>
    );
}