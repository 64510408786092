import React from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import { Adsense } from '@ctrl/react-adsense';

export default function SobreParceiros() {
    return (
        <>
            <Header />
            {/*Featured Slider*/}

            <div>
                <section className="row page-header-parceiro">
                    <div className="container">
                        <h4>Nossos parceiros e amigos</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="featured-content">
                                    <div className="item" ><img style={{ width: '100%' }} src="/images/parceiros/parceiros01.png" alt="imagem"/></div>
                                </div>
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">Você parceiro que ama ajudar a realizar sonhos</h2>
                                    <a href={() => false} className="btn-primary pull-right">Fale conosco</a>
                                </div>
                                <h4>Desperte sua imaginação e transforme sonhos em realidade na Escola Fábrica de Sonhos Virtuais </h4>
                                <h4><b>O lugar onde a criatividade ganha vida digital!</b></h4>
                                <p style={{ textAlign: 'justify' }}>Na Escola Fábrica de Sonhos Virtuais, acreditamos no poder da colaboração e na construção de parcerias sólidas. Estamos em busca de parceiros inspiradores e visionários que compartilhem nossa paixão por educação digital e desejem fazer a diferença no mundo virtual.</p>
                                <p style={{ textAlign: 'justify' }}>Nossos parceiros de cursos digitais têm a oportunidade de se juntar a nós em nossa missão de capacitar pessoas a transformarem seus sonhos virtuais em realidade.</p>
                                <p style={{ textAlign: 'justify' }}>Ao se tornar um parceiro da Escola Fábrica de Sonhos Virtuais, você terá a oportunidade de contribuir com seu expertise e conhecimento para aprimorar nossos cursos existentes e desenvolver novas ofertas educacionais. Valorizamos a diversidade de perspectivas e acreditamos que juntos podemos proporcionar uma experiência de aprendizado ainda mais enriquecedora para nossos alunos.</p>
                                <p style={{ textAlign: 'justify' }}>Além disso, como parceiro, você se beneficiará da exposição e do alcance da nossa plataforma, bem como da oportunidade de colaborar com outros parceiros e profissionais da indústria. Nossa comunidade de parceiros é um ambiente colaborativo onde ideias são compartilhadas, parcerias são formadas e conexões são fortalecidas.</p>
                                <p style={{ textAlign: 'justify' }}>Entre em contato conosco hoje mesmo e descubra como podemos trabalhar juntos para impulsionar a educação digital e transformar a imaginação em realidade virtual. Estamos ansiosos para construir uma parceria de sucesso e fazer grandes coisas juntos!</p>

                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="5379840950"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8105242494"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="autorelaxed"
                                    responsive={true}
                                />
                            </div>
                            <div className="col-sm-12 single-project-sidebar sidebar">
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">Quem já ajuda e promove cada dia mais sonhos</h2>
                                </div>
                                <div className="row event-listing-row">
                                    <div className="col-sm-6 col-md-4 event-listing">
                                        <div className="images_row row m0">
                                            <a href="single-event.html"><img src="/images/duo.png" alt="imagem"/></a>
                                        </div>
                                        <div className="event_excepts row m0">
                                            <h4 className="event_title"><a href="single-event.html">DUO Terceirização</a></h4>
                                            <h5 className="event-place">São Luis - MA</h5>
                                            <p>A Duo Leva A Contabilidade De Uma Forma Diferente</p>
                                            <a href="single-event.html" className="btn-primary btn-outline">conheça</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    );
}