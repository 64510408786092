import React from 'react';
import { StatusScreen } from '@mercadopago/sdk-react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

export default function Notificacao() {
    const queryParams = new URLSearchParams(window.location.search);
    const initializationStatus = {
        paymentId: queryParams.get('p'),
    };

    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };
    const onError = async (error) => {
        console.log(error);
    };
    return (
        <>
            <Header />
            <section className="row content_404">
                <div className="container">
                    <div className="row error_message">
                        <StatusScreen
                            initialization={initializationStatus}
                            onReady={onReady}
                            onError={onError}
                        />
                        <div className='row' style={{ marginTop: 10 }}>
                            <div className='col-sm-8'>
                                <a href='/doacao' className="btn-primary hmfw">Nova Doação</a>
                            </div>
                            <div className='col-sm-4'>
                                <a href='/projetos' className="btn-primary btn-outline hmfw">Ir para Projetos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}