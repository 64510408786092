import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';
import firebase from 'firebase';

export default function MeusCursos() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})
    const [cursos, setCursos] = useState([])
    const [loading,setLoading] = useState(true)
    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((us) => {
                    setUsuario(us.data())
                    servicos.usuarios.doc(user.uid).collection('cursos').get().then((res) => {
                        const data = res.docs.map((d) => d.data())
                        setCursos(data)
                        setLoading(false)
                    })
                })
            }
        })
    }, [])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            <section className="row page-header-contato">
                <div className="container">
                    <h4>Meus Cursos</h4>
                </div>
            </section>

            <section class="row page-content" style={{ backgroundColor: 'white' }}>
                <div class="container">
                    {cursos.length > 0 ?
                        <div class="row event-listing-row">
                            {cursos.map((curso) =>
                                <div class="col-sm-6 col-md-4 event-listing">
                                    <div>
                                        <div className="images_row row m0">
                                            <a href="single-event.html"><img src={curso.miniatura} alt /></a>
                                        </div>
                                        <div className="event_excepts row m0">
                                            <h4 className="event_title"><a href="single-event.html">{curso.titulo}</a></h4>
                                            <h5 class="event-place">{curso.categoria}</h5>
                                            <p>{curso.descricao}</p>
                                            {curso.status === 'ATIVO' ?
                                                <>
                                                    <div className="row fund_progress m0">
                                                        <div className="progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: parseInt((curso.qtdAulas * 100) / curso.totalAulas) + '%' }}>
                                                            </div>
                                                        </div>
                                                        <p style={{ marginTop: 5, fontSize: 12 }}>{parseInt((curso.qtdAulas * 100) / curso.totalAulas) + '%'} / 100%</p>
                                                    </div>
                                                    <a style={{ width: '100%' }} href={"/meucurso?u=" + usuario.id + "&c=" + curso.id} className="btn-primary btn-outline">Ver meu curso</a>
                                                </>
                                                : curso.status === 'PAGAMENTO' ?
                                                    <a style={{ width: '100%' }} href={"/checkout?u=" + usuario.id + "&c=" + curso.id} className="btn-primary btn-outline">Finalizar Compra</a>
                                                    :
                                                    null
                                            }

                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div className="row error_message">
                            <h2 className="error_no"><i class="fa fa-compress" aria-hidden="true"></i></h2>
                            <h4 className="error_details">Você ainda não possui nenhum curso</h4>
                            <div className="row m0 buttons">
                                <a href="/cursos" className="btn-primary">Adquirir meu primeiro curso</a>
                            </div>
                        </div>
                    }

                </div>
            </section>


            <div className="page-wrapper row">
                <Footer />
            </div>



        </>
    );
}