import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

import firebase from 'firebase';
import moment from 'moment';
import { Adsense } from '@ctrl/react-adsense';

export default function Blog() {
    const [servicos] = useState({
        categoriaBlog: firebase.firestore().collection('categoriasBlog').where('status', '==', 'ATIVO'),
        blog: firebase.firestore().collection('blog').where('status', '==', 'ATIVO')
    })

    const [categoriasBlog, setCategoriasBlog] = useState([])
    const [blog, setBlog] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        servicos.categoriaBlog.get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setCategoriasBlog(data)
        })
        servicos.blog.get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setBlog(data)
            setLoading(false)
        })
    }, [servicos.blog, servicos.categoriaBlog])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            {/*Featured Slider*/}
            <section className="row page-header-blog">
                <div className="container">
                    <h4>blog news</h4>
                </div>
            </section>


            <div className="page-wrapper row">
                <section className="row blog-content page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {blog.map((blog) =>
                                    <article key={blog.id} className="post post-type-image row">
                                        <div className="row featured-contents">
                                            <a href={"/post?i=" + blog.id}><img src={blog.imagem} alt='Blog Imagem' /></a>
                                        </div>
                                        <div className="row article-body">
                                            <h3 className="post-title"><a href={"/post?i=" + blog.id}>{blog.titulo}</a>
                                            </h3>
                                            <ul className="post-meta nav">
                                                <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(blog.dataCadastro).format('ll')}</a>
                                                </li>
                                                <li className="post-comments"><i className="fa fa-comments" /> <a href={() => false}>1 comment</a></li>
                                                <li className="posted-by"><i className="fa fa-user" /> <a href={() => false}>{blog.usuario.nome}</a></li>
                                                <li className="category"><i className="fa fa-folder" />{blog.categoria.categoria}</li>
                                            </ul>
                                            <div className="post-excerpt row">
                                                <p>{blog.descricao}</p>
                                            </div>
                                            <a href={"/post?i=" + blog.id} className="btn-primary btn-outline dark">ler mais</a>
                                        </div>
                                    </article>
                                )}
                                <ul className="pagination">
                                    <li className="active"><a href={() => false}>1</a></li>
                                    <li><a href={() => false}>2</a></li>
                                    <li><a href={() => false}>3</a></li>
                                    <li><a href={() => false}>4</a></li>
                                    <li><a href={() => false}>5</a></li>
                                    <li><a href={() => false}><i className="fa fa-angle-double-right" /></a></li>
                                </ul>
                            </div>
                            <div className="col-md-4 sidebar post-sidebar">
                                <div className="row m0 widget widget-search">
                                    <h4 className="widget-title">buscar</h4>
                                    <form action="#" className="row m0 search-form" method="get" role="search">
                                        <div className="input-group">
                                            <input type="search" className="form-control" placeholder="Search here" />
                                            <span className="input-group-addon"><button type="submit"><i className="fa fa-search" /></button></span>
                                        </div>
                                    </form>
                                </div>
                                <div className="row m0 widget widget-tags">
                                    <h4 className="widget-title">Tags</h4>
                                    {categoriasBlog.map((categoria) =>
                                        <a key={categoria.id} href={() => false} className="tag">{categoria.nome}</a>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 sidebar post-sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="2794303541"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>



        </>
    );
}