import React, { useEffect, useState } from 'react';
import { CardPayment, StatusScreen } from '@mercadopago/sdk-react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

const urlProd = "https://us-central1-fabrica-de-sonhos-virtua-e8cae.cloudfunctions.net/app"
const urlHomo = "http://127.0.0.1:5001/fabrica-de-sonhos-virtua-e8cae/us-central1/app"

export default function Doacao() {
    const [valor, setValor] = useState(0)
    const [preference, setPreference] = useState(false)
    const [status, setStatus] = useState(false)
    const [pagamento, setPagamento] = useState('')

    function geraPreferencia() {
        setPreference(true)
    }
    function limpaDados(){
        setValor(0)
        setPreference(false)
        setStatus(false)
        setPagamento('')
    }
    const initialization = {
        amount: parseFloat(valor),
    };
    const initializationStatus = {
        paymentId: pagamento,
    };
    const customization = {
        maxInstallments: 1,
        paymentMethods: {
            creditCard: "all",
        },
    };

    const onSubmit = async (formData) => {
        return new Promise((resolve, reject) => {
            fetch(urlProd+"/process_payment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            }).then((response) => response.json()).then((response) => {
                window.location = '/notificacao?p='+response.id
                setPagamento(response.id)
                setStatus(true)
                resolve();
            }).catch((error) => {
                reject();
            });
        });
    };
    const onError = async (error) => {
        console.log(error);
    };
    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    const onErrorStatus = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };
    const onReadyStatus = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    const handleValor = (event) => {
        event.persist();
        setValor(event.target.value ? parseFloat(event.target.value) : 0);
    }

    return (
        <>
            <Header />

            <div>
                <section className="row page-header">
                    <div className="container">
                        <h4>Doe para alavancar Sonhos virtuais</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">Doe para a Fábrica de Sonhos Virtuais e ajude a tornar sonhos realidade!</h2>
                                </div>
                                <h4>Agradecemos imensamente o seu interesse em contribuir para a Fábrica de Sonhos Virtuais. Oferecemos diferentes maneiras de fazer sua doação:</h4>
                                {/* <h4><b>Por que doar para a Fábrica de Sonhos Virtuais?</b></h4>
                                <p style={{ textAlign: 'justify' }}>A Fábrica de Sonhos Virtuais é um projeto inovador que combina tecnologia e criatividade para criar experiências virtuais imersivas e memoráveis. Nossa missão é transformar sonhos em realidade, utilizando recursos digitais e plataformas interativas para proporcionar momentos mágicos aos nossos participantes. </p>
                                <h4><b>Como você pode ajudar</b></h4>
                                <p style={{ textAlign: 'justify' }}>Estamos à procura de parceiros que compartilhem de nossa visão e queiram se envolver nessa jornada emocionante. Existem diversas formas de contribuir para a Fábrica de Sonhos Virtuais, tais como:</p>
                                <div className="row featureswithImage">
                                    <div className="col-sm-12">
                                        <ul className="list-unstyled">
                                            <li ><b>Investimento financeiro</b><br /><br />Se você acredita em nossa causa e deseja apoiar financeiramente, suas contribuições nos ajudarão a expandir e aprimorar nossas atividades, além de tornar os sonhos de mais pessoas uma realidade. Ao lado direito temos as formas como pode nos ajudar.</li>
                                            <li><b>Parcerias estratégicas</b><br /><br />Se você possui uma empresa ou organização que pode fornecer recursos, conhecimentos ou experiência relevantes para a Fábrica de Sonhos Virtuais, estamos abertos a estabelecer parcerias estratégicas de longo prazo.</li>
                                            <li><b>Voluntariado</b><br /><br />Caso queira se envolver de forma prática e se dedicar ao projeto, estamos sempre em busca de voluntários com habilidades em áreas como desenvolvimento de software, design, animação, marketing, entre outras.</li>
                                            <li><b>Divulgação e apoio</b><br /><br />Você também pode nos ajudar compartilhando nossa missão com sua rede de contatos, em suas redes sociais ou através de qualquer outro meio de comunicação. Todo apoio e divulgação são fundamentais para o sucesso do projeto.</li>

                                        </ul>
                                    </div>
                                </div> */}
                                <div className="row tabs-row shortcodeBlock m0" id="tabs">
                                    <h2 className="hhh h1 shortcodeName">Formas de doação</h2>
                                    {/* Nav tabs */}
                                    <ul className="nav nav-tabs hhh-tab" role="tablist">
                                        <li role="presentation" className="active"><a href="#tabs1" aria-controls="tabs1" role="tab" data-toggle="tab">Doação Online</a></li>
                                        <li role="presentation"><a href="#tabs2" aria-controls="tabs2" role="tab" data-toggle="tab">PIX</a></li>
                                        <li role="presentation"><a href="#tabs3" aria-controls="tabs3" role="tab" data-toggle="tab">Doar Equipamentos digitais</a></li>
                                    </ul>
                                    {/* Tab panes */}
                                    <div className="tab-content hhh-tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="tabs1" >
                                            <h3 className="tab-heading hhh h1">Doação Online pelo Mercado Pago</h3>
                                            <p>Mercado Pago no site oferece medidas robustas de segurança, como criptografia de dados, proteção contra fraudes, certificados de segurança, autenticação em duas etapas, monitoramento constante e um processo de disputa e reembolso. Essas medidas visam garantir a segurança das transações realizadas na plataforma e proporcionar uma experiência confiável aos usuários. <a href='https://www.mercadopago.com.br/'>Clique aqui e conheça o mercado pago.</a></p>

                                            {!status ?
                                                <>
                                                    <p><b>Voce está doando: </b>{valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <div className='row'>
                                                        <div className='col-sm-3'>
                                                            {/* <label>Digite o valor da sua doação</label> */}
                                                            <div className="contact-form " >
                                                                <input value={valor} onChange={handleValor} type="text" className="form-control hmfw" placeholder="R$ 0,00" />
                                                            </div>

                                                        </div>
                                                        <div className='col-sm-3'>
                                                            <button onClick={() => geraPreferencia()} className="btn-primary hmfw">Realizar Doação</button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                            }

                                            {preference ?
                                                <div >
                                                    {!status ?
                                                        <CardPayment
                                                            initialization={initialization}
                                                            customization={customization}
                                                            onSubmit={onSubmit}
                                                            onReady={onReady}
                                                            onError={onError}
                                                        />
                                                        :
                                                        <>
                                                            <StatusScreen
                                                                initialization={initializationStatus}
                                                                onReady={onReadyStatus}
                                                                onError={onErrorStatus}
                                                            />
                                                            <div className='row' style={{ marginTop: 10 }}>
                                                                <div className='col-sm-8'>
                                                                    <button onClick={() => limpaDados()} className="btn-primary hmfw">Nova Doação</button>
                                                                </div>
                                                                <div className='col-sm-4'>
                                                                    <a href='/projetos' className="btn-primary btn-outline hmfw">Ir para Projetos</a>
                                                                </div>
                                                            </div>
                                                        </>

                                                    }

                                                </div>
                                                :
                                                null
                                            }

                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="tabs2">
                                            <h3 className="tab-heading hhh h1">Doação por PIX</h3>
                                            <p>Doações via PIX não é necessário enviar comprovantes, cada doação será recebida com carinho e investido em equipamentos e mais cursos.</p>
                                            <p><b>Dados do PIX</b></p>
                                            <p><b>Tipo PIX:</b> Email</p>
                                            <p><b>Chave PIX:</b> comercial@fabricadesonhosvirtuais.com.br</p>
                                            <p><b>Favorecido</b> ESCOLA FÁBRICA DE SONHOS VIRTUAIS</p>
                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="tabs3">
                                            <h3 className="tab-heading hhh h1">Equipamentos digitais</h3>
                                            <p>A doação de equipamentos digitais refere-se ao ato de fornecer dispositivos eletrônicos, como computadores, laptops, tablets, smartphones e outros dispositivos relacionados.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <div className="row m0 widget widget-similar-project widget-similar">
                                    <div className="similar-project">
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={() => false}>Realização de Sonhos</a></h4>
                                                <p style={{ fontSize: 13 }}>Ao doar para a Fábrica de Sonhos Virtuais, você estará contribuindo diretamente para a realização de sonhos de pessoas que enfrentam dificuldades em suas vidas. Nós trabalhamos em estreita colaboração com os sonhadores e suas famílias para criar experiências únicas e memoráveis, tornando seus sonhos uma realidade.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="similar-project">
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={() => false}>Impacto Duradouro</a></h4>
                                                <p style={{ fontSize: 13 }}>Cada doação que recebemos nos permite expandir nosso alcance e ajudar mais pessoas. Ao apoiar a Fábrica de Sonhos Virtuais, você está investindo em um impacto duradouro, fornecendo momentos especiais que ficarão para sempre na memória dos sonhadores e suas famílias.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="similar-project">
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={() => false}>Diversidade de Sonhos</a></h4>
                                                <p style={{ fontSize: 13 }}>Trabalhamos com sonhos de diferentes categorias, desde experiências incríveis até oportunidades educacionais, passando por encontros com personalidades inspiradoras. Independentemente do sonho, nosso objetivo é torná-lo uma realidade e espalhar alegria e esperança.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    );
}