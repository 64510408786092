import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../../componentes/Header'
import Footer from '../../../componentes/Footer';

import firebase from 'firebase';
import moment from 'moment';
import { Adsense } from '@ctrl/react-adsense';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';

export default function MeuCurso() {
    const queryParams = new URLSearchParams(window.location.search);
    const [idUsuario] = useState(queryParams.get('u'))
    const [idCurso] = useState(queryParams.get('c'))
    const [servicos] = useState({
        cursos: firebase.firestore().collection('usuarios').doc(idUsuario).collection('cursos').doc(idCurso),
        curso: firebase.firestore().collection('cursos')
    })

    const [meuCurso, setMeuCurso] = useState({})
    const [curso, setCurso] = useState({})
    const [modulos, setModulos] = useState([])
    const [aulas, setAulas] = useState([])
    const [modulo, setModulo] = useState({})
    const [aula, setAula] = useState({})
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        servicos.cursos.get().then((res) => {
            setMeuCurso(res.data())
            servicos.curso.doc(res.data().idCurso).get().then((c) => {
                setCurso(c.data())
                servicos.curso.doc(c.data().id).collection('modulos').where('status', '==', 'ATIVO').get().then((mod) => {
                    const data = mod.docs.map((d) => d.data())
                    setModulos(data)
                    servicos.curso.doc(c.data().id).collection('aulas').get().then((au) => {
                        const dataAu = au.docs.map((d) => d.data())
                        setAula(dataAu.filter((d) => d.id === res.data().aulaAtual)[0])
                        setAulas(dataAu)
                        servicos.cursos.update({
                            totalAulas: parseInt(dataAu.length)
                        })
                        setLoading(false)
                    })
                })
            })
        })
    }, [])

    function selecionaAula(mod, aula) {
        setAula({})
        mensagemLoading('Carregando Aula...')
        setModulo(mod)
        servicos.curso.doc(curso.id).collection('aulas').doc(aula).get().then((mod) => {
            setAula(mod.data())
            Swal.close();
        })

    }

    function proximaAula(au) {
        mensagemLoading('Carregando Aula..')
        servicos.curso.doc(curso.id).collection('aulas').where('posicao', '==', parseInt(au.posicao + 1)).get().then((mod) => {
            if (mod.size > 0) {
                servicos.cursos.update({
                    aulaAtual: mod.docs.map((d) => d.data())[0].id
                }).then(() => {
                    setAula(mod.docs.map((d) => d.data())[0])
                    Swal.close();
                })
            } else {
                mensagemAlerta('Não existem mais aulas')
            }
        })
    }
    function aulaAnterior(au) {
        mensagemLoading('Carregando Aula..')
        servicos.curso.doc(curso.id).collection('aulas').where('posicao', '==', parseInt(au.posicao - 1)).get().then((mod) => {
            if (mod.size > 0) {
                servicos.cursos.update({
                    aulaAtual: mod.docs.map((d) => d.data())[0].id
                }).then(() => {
                    setAula(mod.docs.map((d) => d.data())[0])
                    Swal.close();
                })
            } else {
                mensagemAlerta('Não existem mais aulas')
            }
        })
    }

    function marcaConcluido(au) {
        Swal.fire({
            title: 'Deseja marcar esta aula como concluída?',
            text: 'Ao confirmar você fica ciente que estudou o vídeo completo.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Marcando como concluído..')
                servicos.cursos.collection('concluidos').where('idAula', '==', au.id).get().then((a) => {
                    if (a.size > 0) {
                        mensagemAlerta('Esta aula já está concluída! Clique a próxima aula')
                    } else {
                        servicos.cursos.collection('concluidos').add({
                            idAula: au.id,
                            dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                        }).then(() => {
                            servicos.cursos.update({
                                qtdAulas: parseInt(meuCurso.qtdAulas + 1)
                            }).then(() => {
                                servicos.curso.doc(curso.id).collection('aulas').where('posicao', '==', parseInt(au.posicao + 1)).get().then((mod) => {
                                    if (mod.size > 0) {
                                        servicos.cursos.update({
                                            aulaAtual: mod.docs.map((d) => d.data())[0].id
                                        }).then(() => {
                                            meuCurso.qtdAulas = parseInt(meuCurso.qtdAulas + 1)
                                            setAula(mod.docs.map((d) => d.data())[0])
                                            Swal.close();
                                        })
                                    }
                                })
                            })
                        })
                    }
                })

            }
        })
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            <div className="page-wrapper row">
                <section className="row blog-content page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {aula && aula.id ?
                                    <>
                                        <div className="row cause-item education">
                                            <div className="cause_excepts row m0">
                                                <h4 className="cuase_title"><a href="single-cause.html">PROGRESSO DO SEU CURSO</a></h4>
                                                <div className="row fund_progress m0">
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: parseInt((meuCurso.qtdAulas * 100) / aulas.length) + '%' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row fund_raises m0">
                                                    <div className="pull-left raised amount_box">
                                                        <h6>aulas concluídas</h6>
                                                        <h3>{meuCurso.qtdAulas}</h3>
                                                    </div>
                                                    <div className="pull-left goal amount_box">
                                                        <h6>total aulas</h6>
                                                        <h3>{aulas.length}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <article className="post post-type-video row">
                                            <div className="row featured-contents" style={{ marginBottom: 20 }}>
                                                <video
                                                    src={aula.video}
                                                    height={'100%'}
                                                    width={'100%'}
                                                    controls
                                                    controlsList="nodownload "
                                                />
                                            </div>
                                            <div className="row " >
                                                <div className='col-md-3' >
                                                    <button onClick={() => aulaAnterior(aula)} style={{ width: '100%', marginBottom: 5 }} type="button" defaultValue="Verificar" className="btn-primary"> <i className="fa fa-angle-left"></i> Aula Anterior</button>
                                                </div>
                                                <div className='col-md-6' >
                                                    <button onClick={() => marcaConcluido(aula)} style={{ width: '100%', marginBottom: 5 }} type="button" defaultValue="Verificar" className="btn-primary">  Marcar como concluído</button>
                                                </div>
                                                <div className='col-md-3' >
                                                    <button onClick={() => proximaAula(aula)} style={{ width: '100%' }} type="button" defaultValue="Verificar" className="btn-primary">Próxima Aula <i className="fa fa-angle-right"></i></button>
                                                </div>

                                            </div>

                                            <div className="row article-body" style={{ marginTop: 40 }}>
                                                <h3 className="post-title"><a href={() => false}>Aula {aula.posicao} - {aula.titulo}</a>

                                                </h3>
                                                <ul className="post-meta nav">
                                                    <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(aula.dataAtualizacao).format('DD/MM/YYYY')}</a>
                                                    </li>
                                                    <li className="post-comments"><i className="fa fa-comments" /> <a href={() => false}>Módulo {modulo.posicao}. {modulo.titulo}</a></li>
                                                    <li className="posted-by"><i className="fa fa-clock-o" /><a href={() => false}>{aula.horas} minutos</a></li>
                                                    <li className="category"><i className="fa fa-star" /><a href={() => false}>{aula.classificacao}</a></li>
                                                </ul>
                                            </div>
                                            <div className="row tabs-row shortcodeBlock m0" id="tabs" style={{ marginTop: 50 }}>
                                                {/* Nav tabs */}
                                                <ul className="nav nav-tabs hhh-tab" role="tablist">
                                                    <li role="presentation" className="active"><a href="#tabs1" aria-controls="tabs1" role="tab" data-toggle="tab">Visão geral</a></li>
                                                    <li role="presentation"><a href="#tabs2" aria-controls="tabs2" role="tab" data-toggle="tab">conteúdo da Aula</a></li>
                                                    <li role="presentation"><a href="#tabs3" aria-controls="tabs3" role="tab" data-toggle="tab">dúvidas e respostas</a></li>
                                                    <li role="presentation"><a href="#tabs4" aria-controls="tabs4" role="tab" data-toggle="tab">avaliações </a></li>
                                                </ul>
                                                {/* Tab panes */}
                                                <div className="tab-content hhh-tab-content">
                                                    <div role="tabpanel" className="tab-pane active" id="tabs1">
                                                        <div className="single-project">
                                                            <h4 className="tab-heading hhh h1">Sobre esta Aula</h4>
                                                            <p>{parse(aula.conteudo)}</p>
                                                            <h4 className="tab-heading hhh h1" style={{ marginTop: 20 }}>Sobre o Curso</h4>
                                                            <p>{curso.descricao} </p>
                                                            <h4 className="tab-heading hhh h1" style={{ marginTop: 20 }}>Dados do Professor</h4>
                                                            <p>{curso.descricao} </p>
                                                        </div>
                                                    </div>
                                                    <div role="tabpanel" className="tab-pane" id="tabs2">
                                                        <div className="single-project">
                                                            <h4 className="tab-heading hhh h1">Arquivos importantes da Aula</h4>
                                                            <div className='row'>
                                                                <div className='col-md-10'>
                                                                    <h4 style={{ fontSize: 15 }}> Arquivo para aula e como voce faz tudo isso</h4>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <button type="button" className="btn-primary"><i className="fa fa-download"></i></button>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-10'>
                                                                    <h4 style={{ fontSize: 15 }}> Arquivo para aula e como voce faz tudo isso</h4>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <button type="button" className="btn-primary"><i className="fa fa-download"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div role="tabpanel" className="tab-pane" id="tabs3">
                                                        <div className="single-project">
                                                            <h4 className="tab-heading hhh h1">Tire suas dúvidas desta aula</h4>
                                                            <p>Área para compartilhamento de dúvidas e respostas, nessa aba você pode fazer perguntas que o professor irá responder. Assim tirar dúvidas junto aos seus colegas. </p>
                                                            <div className="single-project" style={{ marginTop: 20 }}>
                                                                <h4 className="tab-heading hhh h1">Dúvidas Frequentes (10)</h4>
                                                                <div className='row'>
                                                                    <div className='col-md-1' >
                                                                        <img style={{ width: 50, height: 50 }} src='https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png' />
                                                                    </div>
                                                                    <div className='col-md-11'>
                                                                        <h5><b>Teste</b></h5>
                                                                        <p >sjdhfjhsadfjkhakshfjksahjk</p>
                                                                        <ul className="post-meta nav">
                                                                            <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(aula.dataAtualizacao).format('DD/MM/YYYY')}</a></li>
                                                                            <li className="category"><i className="fa fa-users" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                            <li className="category"><i className="fa fa-comments" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='col-md-12' style={{ width: '100%' }}>___</div>
                                                                </div>
                                                                <div className='row' style={{ marginTop: 20 }}>
                                                                    <div className='col-md-1' >
                                                                        <img style={{ width: 50, height: 50 }} src='https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png' />
                                                                    </div>
                                                                    <div className='col-md-11'>
                                                                        <h5><b>Teste</b></h5>
                                                                        <p >sjdhfjhsadfjkhakshfjksahjk</p>
                                                                        <ul className="post-meta nav">
                                                                            <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(aula.dataAtualizacao).format('DD/MM/YYYY')}</a></li>
                                                                            <li className="category"><i className="fa fa-users" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                            <li className="category"><i className="fa fa-comments" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='col-md-12' style={{ width: '100%' }}>___</div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div role="tabpanel" className="tab-pane" id="tabs4">
                                                        <div className="single-project">
                                                            <h4 className="tab-heading hhh h1">Notas dos Alunos</h4>
                                                        </div>
                                                        <div className='row' >
                                                            <div className='col-md-3' style={{ textAlign: 'center' }}>
                                                                <h1 className="tab-heading hhh h1" style={{ fontSize: 50, textAlign: 'center', color: '#125431' }}>4.7</h1>
                                                                <div className='row' >
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                </div>
                                                                <p>Avaliações</p>
                                                            </div>
                                                            <div className='col-md-2' style={{ textAlign: 'center' }}>
                                                                <div className='row' style={{ marginTop: 5 }} >
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='row' style={{ marginTop: 4 }} >
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='row' style={{ marginTop: 5 }}  >
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='row' style={{ marginTop: 4 }}>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='row' style={{ marginTop: 4 }}>
                                                                    <i style={{ color: '#DD971b' }} class="fa fa-star " aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6' style={{ marginTop: 10 }} >
                                                                <div className="row fund_progress m0">
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: '10%' }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row fund_progress m0" style={{ marginTop: 20 }}>
                                                                    <div className="progress ">
                                                                        <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: '10%' }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row fund_progress m0" style={{ marginTop: 20 }}>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: '10%' }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row fund_progress m0" style={{ marginTop: 20 }}>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: '10%' }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row fund_progress m0" style={{ marginTop: 20 }}>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ width: '10%' }}>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="single-project" style={{ marginTop: 20 }}>
                                                            <h4 className="tab-heading hhh h1">Comentários</h4>
                                                            <div className='row'>
                                                                <div className='col-md-1' >
                                                                    <img style={{ width: 50, height: 50 }} src='https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png' />
                                                                </div>
                                                                <div className='col-md-11'>
                                                                    <h5><b>Teste</b></h5>
                                                                    <p >sjdhfjhsadfjkhakshfjksahjk</p>
                                                                    <ul className="post-meta nav">
                                                                        <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(aula.dataAtualizacao).format('DD/MM/YYYY')}</a></li>
                                                                        <li className="category"><i className="fa fa-users" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                        <li className="category"><i className="fa fa-comments" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                    </ul>
                                                                </div>
                                                                <div className='col-md-12' style={{ width: '100%' }}>___</div>
                                                            </div>
                                                            <div className='row' style={{ marginTop: 20 }}>
                                                                <div className='col-md-1' >
                                                                    <img style={{ width: 50, height: 50 }} src='https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png' />
                                                                </div>
                                                                <div className='col-md-11'>
                                                                    <h5><b>Teste</b></h5>
                                                                    <p >sjdhfjhsadfjkhakshfjksahjk</p>
                                                                    <ul className="post-meta nav">
                                                                        <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(aula.dataAtualizacao).format('DD/MM/YYYY')}</a></li>
                                                                        <li className="category"><i className="fa fa-users" /><a href={() => false}>{aula.classificacao}</a></li>
                                                                    </ul>
                                                                </div>
                                                                <div className='col-md-12' style={{ width: '100%' }}>___</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </>
                                    :
                                    null
                                }

                            </div>
                            <div className="col-md-4 sidebar post-sidebar">
                                <div className="row m0 questions" id="general">
                                    <h3 className="question_type">conteúdo do curso</h3>
                                    {modulos.length > 0 ?
                                        <div className="panel-group question_accordion" id="question_accordion1" role="tablist" aria-multiselectable="true">
                                            {modulos.sort((a, b) => {
                                                if (parseInt(a.posicao) < parseInt(b.posicao))
                                                    return -1;
                                                if (parseInt(a.posicao) > parseInt(b.posicao))
                                                    return 1;
                                                return 0;
                                            }).map((modulo) =>
                                                <div key={modulo.id} className="panel panel-default">
                                                    <div className="panel-heading" role="tab" id={modulo.id}>
                                                        <h4 className="panel-title">
                                                            <a style={{ fontSize: 13 }} className="collapsed" role="button" data-toggle="collapse" data-parent="#question_accordion1" href={"#collapse1" + modulo.id} aria-expanded="false" aria-controls="collapse1">
                                                                Módulo {modulo.posicao} - {modulo.titulo}
                                                                <p style={{ fontSize: 13 }}>{aulas.filter((d) => d.modulo === modulo.id).length} <i class="fa fa-video-camera" aria-hidden="true" ></i></p>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id={"collapse1" + modulo.id} className="panel-collapse collapse" role="tabpanel" aria-labelledby={modulo.id}>
                                                        <div className="panel-body" >
                                                            {/* <p onClick={() => selecionaAula("flIVuIgO7oyNkZXJTiuk", modulo, "LNCUL7qqZ65edklarssZ")}><input type="checkbox" id="scales" name="scales" checked /> <b>01. Aula de tudo</b></p> */}
                                                            {aulas.length > 0 ?
                                                                <ul className="nav recent-posts">
                                                                    {aulas.sort((a, b) => {
                                                                        if (parseInt(a.posicao) < parseInt(b.posicao))
                                                                            return -1;
                                                                        if (parseInt(a.posicao) > parseInt(b.posicao))
                                                                            return 1;
                                                                        return 0;
                                                                    }).filter((e) => e.modulo === modulo.id).map((a) =>
                                                                        <li key={a.id}><a href={() => false} ><b style={{ color: 'black' }} key={a.id} onClick={() => selecionaAula(modulo, a.id)}>{a.posicao}.{a.titulo} - <i className="fa fa-clock-o" /> {a.horas}m</b></a></li>
                                                                    )}
                                                                </ul>
                                                                :
                                                                null
                                                            }

                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        null
                                    }

                                </div>

                            </div>
                            <div className="col-md-4 sidebar post-sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="2794303541"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}