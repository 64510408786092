import React, { useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import { Adsense } from '@ctrl/react-adsense';
import { buscaEndereco } from '../../services/WsConnect'
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';

export default function Projetos() {
    const [servicos] = useState({
        parceiros: firebase.firestore().collection('parceiros'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [tipoParceiro, setTipoParceiro] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [complemento, setComplemento] = useState('')
    const [referencia, setReferencia] = useState('')
    const [envio, setEnvio] = useState(false)

    function enviaParceiro() {
        if (!nome) {
            mensagemAlerta('Por favor , digite o seu Nome / Nome da Empresa')
            return
        }
        if (!email || !email.includes('@')) {
            mensagemAlerta('Email vazio ou inválido')
            return
        }
        if (!ddd || ddd.length < 2 ) {
            mensagemAlerta('DDD vazio ou incompleto')
            return
        }
        if (!celular || celular.length < 9 ) {
            mensagemAlerta('Celular vazio ou incompleto! Nào esqueça de colocar o 9 na frente')
            return
        }
        if (!tipoParceiro) {
            mensagemAlerta('Escolha entre os tipos de parceiro que você é.')
            return
        }
        if (!cep || cep.length < 8) {
            mensagemAlerta('CEP vazio ou incompleto')
            return
        }
        if (!endereco) {
            mensagemAlerta('Complete seu Endereço')
            return
        }
        if (!bairro) {
            mensagemAlerta('Complete seu Bairro')
            return
        }
        if (!numero) {
            mensagemAlerta('Digite o número ')
            return
        }
        if (!endereco) {
            mensagemAlerta('Complete seu Endereço')
            return
        }
        mensagemLoading('Enviando solicitação...')
        servicos.parceiros.where('email','==',email).get().then((res)=>{
            if(res.size > 0){
                mensagemAlerta('Aguarde.. Seus dados já estão em análise!')
            }else{
                servicos.usuarios.where('email','==',email).get().then((user)=>{
                    if(user.size > 0){
                        mensagemAlerta('Você já faz parte do Fábrica de Sonhos Virtuais')
                    }else{
                        servicos.parceiros.add({
                            nome:nome,
                            email:email,
                            ddd:ddd,
                            celular:celular,
                            tipoParceiro:tipoParceiro,
                            endereco:{
                                cep:cep,
                                endereco:endereco,
                                bairro:bairro,
                                numero:numero,
                                uf:uf,
                                cidade:cidade,
                                complemento:complemento,
                                referencia:referencia,
                            },
                            dataCadastro:moment().format('YYYY-MM-DD HH:mm')
                        }).then((par)=>{
                            servicos.parceiros.doc(par.id).update({
                                id:par.id
                            }).then(()=>{
                                Swal.close();
                                setEnvio(true)
                            })
                        }).catch(()=>{
                            mensagemErro('Erro ao enviar solicitação! Tente mais tarde')
                        })
                    }
                })
            }
        })
        
    }

    // Inputs
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleTipoParceiro = (event) => {
        event.persist();
        setTipoParceiro(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaEndereco(event.target.value).then((res) => {
                if (res.erro) {
                    mensagemErro('Endereço não localizado')
                    setEndereco('')
                    setBairro('')
                    setUf('')
                    setCidade('')
                } else {
                    setEndereco(res.logradouro)
                    setBairro(res.bairro)
                    setUf(res.uf)
                    setCidade(res.localidade)
                }

            })
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }


    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <Header />

            <div>
                <section className="row page-header-parceiro">
                    <div className="container">
                        <h4>Seja um parceiro da Escola Fábrica de Sonhos Virtuais</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="featured-content">

                                    <div className="item"><img src="/images/parceiros/parceiros.png" alt="imagem" /></div>
                                </div>
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">Bem-vindo à Fábrica de Sonhos Virtuais</h2>
                                    <a href={() => false} className="btn-primary pull-right">Fale conosco</a>
                                </div>
                                <h4>Agradecemos o seu interesse em se tornar um parceiro da Fábrica de Sonhos Virtuais. Somos uma iniciativa que busca realizar sonhos e proporcionar experiências virtuais únicas para pessoas de todas as idades.</h4>
                                <h4><b>Sobre o Projeto</b></h4>
                                <p style={{ textAlign: 'justify' }}>A Fábrica de Sonhos Virtuais é um projeto inovador que combina tecnologia e criatividade para criar experiências virtuais imersivas e memoráveis. Nossa missão é transformar sonhos em realidade, utilizando recursos digitais e plataformas interativas para proporcionar momentos mágicos aos nossos participantes. </p>
                                <h4><b>Como você pode ajudar</b></h4>
                                <p style={{ textAlign: 'justify' }}>Estamos à procura de parceiros que compartilhem de nossa visão e queiram se envolver nessa jornada emocionante. Existem diversas formas de contribuir para a Fábrica de Sonhos Virtuais, tais como:</p>
                                <div className="row featureswithImage">
                                    <div className="col-sm-12">
                                        <ul className="list-unstyled">
                                            <li ><b>Investimento financeiro</b><br /><br />Se você acredita em nossa causa e deseja apoiar financeiramente, suas contribuições nos ajudarão a expandir e aprimorar nossas atividades, além de tornar os sonhos de mais pessoas uma realidade. Ao lado direito temos as formas como pode nos ajudar.</li>
                                            <li><b>Parcerias estratégicas</b><br /><br />Se você possui uma empresa ou organização que pode fornecer recursos, conhecimentos ou experiência relevantes para a Fábrica de Sonhos Virtuais, estamos abertos a estabelecer parcerias estratégicas de longo prazo.</li>
                                            <li><b>Voluntariado</b><br /><br />Caso queira se envolver de forma prática e se dedicar ao projeto, estamos sempre em busca de voluntários com habilidades em áreas como desenvolvimento de software, design, animação, marketing, entre outras.</li>
                                            <li><b>Divulgação e apoio</b><br /><br />Você também pode nos ajudar compartilhando nossa missão com sua rede de contatos, em suas redes sociais ou através de qualquer outro meio de comunicação. Todo apoio e divulgação são fundamentais para o sucesso do projeto.</li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="text-block row">Agradecemos novamente pelo seu interesse em fazer parte da Fábrica de Sonhos Virtuais. Juntos, podemos tornar o mundo um lugar mais mágico e cheio de possibilidades!</div>
                                <a href='/doacao' className="btn-primary hmfw">Quero fazer doação online agora</a>
                            </div>
                            {!envio ?
                                <div className="col-md-4">
                                    <div className="become_volunteer row m0">
                                        <h3 className="hhh h1">Novo Parceiro</h3>
                                        <h4 className="hhh h2">Preencha o formulário abaixo</h4>
                                        <p>Campos com * são obrigatórios</p>
                                        <div className="form-group">
                                            <label htmlFor="name">Nome / Empresa *</label>
                                            <input value={nome} onChange={handleNome} type="text" id="name" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email *</label>
                                            <input value={email} onChange={handleEmail} type="email" id="email" className="form-control" />
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-3">
                                                <div className="form-group">
                                                    <label htmlFor="tel">DDD *</label>
                                                    <input value={ddd} onChange={handleDDD} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-xs-9">
                                                <div className="form-group">
                                                    <label htmlFor="tel">Celular / Whatsapp *</label>
                                                    <input value={celular} onChange={handleCelular} type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="country">Tipo de Parceiro*</label>
                                            <select name="country" id="country" onChange={handleTipoParceiro} className="form-control">
                                                <option value={0} selected>&nbsp;</option>
                                                <option value={"GOVERNO"}>Governamental</option>
                                                <option value={"PRIVADO"}>Empresa Privada</option>
                                                <option value={"PROFESSOR"}>Professor de Tecnologia</option>
                                                <option value={"VOLUNTARIO"}>Voluntário</option>
                                            </select>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-5">
                                                <div className="form-group">
                                                    <label htmlFor="tel">CEP *</label>
                                                    <input value={cep} onChange={handleCep} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-xs-7">
                                                <div className="form-group">
                                                    <label htmlFor="tel">Endereço *</label>
                                                    <input value={endereco} onChange={handleEndereco} type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-8">
                                                <div className="form-group">
                                                    <label htmlFor="tel">Bairro *</label>
                                                    <input value={bairro} onChange={handleBairro} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-xs-4">
                                                <div className="form-group">
                                                    <label htmlFor="tel">N *</label>
                                                    <input value={numero} onChange={handleNumero} type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-3">
                                                <div className="form-group">
                                                    <label htmlFor="address">UF</label>
                                                    <input value={uf} disabled type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-xs-9">
                                                <div className="form-group">
                                                    <label htmlFor="address">Cidade</label>
                                                    <input value={cidade} disabled type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">Complemento</label>
                                            <input value={complemento} onChange={handleComplemento} type="text" id="address" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">Referência</label>
                                            <input value={referencia} onChange={handleReferencia} type="text" id="address" className="form-control" />
                                        </div>

                                        <div className="row m0 text-right" >
                                            <input onClick={()=> enviaParceiro()} type="button" defaultValue="Enviar solicitação" className="btn-primary" style={{width:'100%'}} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-sm-4 single-project-sidebar sidebar">
                                    <div className="row m0 widget widget-similar-project widget-similar">
                                        <div className="similar-project">
                                            <div className="row gallery-item">
                                                <div className="inner row" style={{ textAlign: 'center' }}>
                                                    <i style={{ color: 'green' }} class="fa fa-check fa-5x" aria-hidden="true"></i>
                                                    <h4 className="title">Solicitação enviada</h4>
                                                    <p style={{ fontSize: 13 }}>Recebemos sua solicitação! Breve entraremos em contato para estreitar as relações de parceria.</p>
                                                    <a href='/' className="btn-primary hmfw">Continuar no site</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="5379840950"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <div className="row m0 widget widget-similar-project widget-similar">
                                    <div className="similar-project">
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={"/doacao"}>DOAÇÃO ONLINE</a></h4>
                                                <p style={{ fontSize: 13 }}>Abaixo está as opções de doação financeira! Clique no botão abaixo e saiba como realizar sua doação online</p>
                                                <a href='/doacao' className="btn-primary hmfw">Clique aqui</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    );
}