
export const carregaEstados = () => {
    const URL = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

// Carrega Cidades
export const carregaCidade = (uf) => {
    const URL = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

// Seleciona UF
export const selecionaUf = (uf) => {
    const URL = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

//Busca cep
export const buscaEndereco = (cep) => {
    const URL = `https://viacep.com.br/ws/${cep}/json/`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}