import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { buscaEndereco } from '../../services/WsConnect';
import InputMask from "react-input-mask";

export default function Cadastro() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})

    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [cpf, setCpf] = useState('')
    const [celular, setCelular] = useState('')
    const [email, setEmail] = useState('')
    const [pai, setPai] = useState('')
    const [mae, setMae] = useState('')

    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [referencia, setReferencia] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    const [escola, setEscola] = useState('')
    const [serie, setSerie] = useState('')
    const [turno, setTurno] = useState('')
    const [qtdAlunos, setQtdAlunos] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                    setNome(res.data().nome)
                    setSobrenome(res.data().sobrenome)
                    setCpf(res.data().cpf)
                    setCelular(res.data().celular)
                    setEmail(res.data().email)
                    setMae(res.data().mae)
                    setPai(res.data().pai)
                    setCep(res.data().endereco.cep)
                    setEndereco(res.data().endereco.endereco)
                    setBairro(res.data().endereco.bairro)
                    setNumero(res.data().endereco.numero)
                    setComplemento(res.data().endereco.complemento)
                    setReferencia(res.data().endereco.referencia)
                    setUf(res.data().endereco.uf)
                    setCidade(res.data().endereco.cidade)
                    setEscola(res.data().escola)
                    setSerie(res.data().serie)
                    setTurno(res.data().turno)
                    setQtdAlunos(res.data().qtdAlunos)
                })
            }
        })
    }, [])

    function atualizaDados() {
        if (!nome) {
            mensagemAlerta('Favor digite seu nome')
            return
        }
        if (!sobrenome) {
            mensagemAlerta('Favor digite seu sobrenome')
            return
        }
        if (!celular || celular.length < 11) {
            mensagemAlerta('O celular deve ter 11 caracteres, adicionando o DDD e o 9 na frente')
            return
        }
        if (!cep) {
            mensagemAlerta('Favor digite seu CEP')
            return
        }
        if (!endereco) {
            mensagemAlerta('Favor digite seu Endereço')
            return
        }
        if (!bairro) {
            mensagemAlerta('Favor digite seu bairro')
            return
        }
        if (!numero) {
            mensagemAlerta('Favor digite seu numero')
            return
        }
        mensagemLoading('Atualizando dados..')
        servicos.usuarios.doc(usuario.id).update({
            nome: nome,
            sobrenome: sobrenome,
            celular: celular,
            endereco: {
                cep: cep,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                complemento: complemento,
                referencia: referencia,
                cidade: cidade,
                uf: uf
            }
        }).then(() => {
            Swal.close();
            mensagemSucesso('Dados atualizado com sucesso!')
        })
    }

    // Inputs
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleNomeMae = (event) => {
        event.persist();
        setMae(event.target.value);
    }
    const handleNomePai = (event) => {
        event.persist();
        setPai(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaEndereco(event.target.value).then((res) => {
                if (res.erro) {
                    mensagemErro('Endereço não localizado')
                    setEndereco('')
                    setBairro('')
                    setUf('')
                    setCidade('')
                } else {
                    setEndereco(res.logradouro)
                    setBairro(res.bairro)
                    setUf(res.uf)
                    setCidade(res.localidade)
                }
            })
        } else {
            setEndereco('')
            setBairro('')
            setUf('')
            setCidade('')
            setComplemento('')
            setReferencia('')
            setUf('')
            setCidade('')
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }
    const handleEscola = (event) => {
        event.persist();
        setEscola(event.target.value);
    }
    const handleSerie = (event) => {
        event.persist();
        setSerie(event.target.value);
    }
    const handleTurno = (event) => {
        event.persist();
        setTurno(event.target.value);
    }
    const handleQtdAlunos = (event) => {
        event.persist();
        setQtdAlunos(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/';
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function sair() {
        servicos.auth.signOut().then(() => {
            window.location = '/'
        })
    }


    return (
        <>
            <Header />
            <section className="row page-header-contato">
                <div className="container">
                    <h4>Novo Cadastro</h4>
                </div>
            </section>
            <section className="row team-content page-content" style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="row team_members_row">
                        <div className="col-sm-4">
                            <div className="row board_head m0">
                                <img style={{ height: 449 }} src='https://cdn-icons-png.flaticon.com/512/1361/1361728.png' alt='' className="img-responsive" />
                                <div className="memberIntro row m0">
                                    <h6>Clique aqui Trocar minha imagem do perfil</h6>
                                    <input type='file' accept='.png, .jpeg, .jpg' style={{ marginTop: 10 }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <h3 className="team_page_title">Dados Pessoais</h3>
                            <p>Campos com o ícone <i style={{ color: 'red' }} className="fa fa-pencil" aria-hidden="true"></i> são obrigatórios</p>
                            <div className="row checkout-form" style={{ marginTop: 30 }}>
                                <div className='col-md-6' >
                                    <label>Nome <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input value={nome} onChange={handleNome} type="text" className="form-control" placeholder="Seu nome" />

                                </div>
                                <div className='col-md-6' >
                                    <label>Sobrenome <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input value={sobrenome} onChange={handleSobrenome} type="text" className="form-control" placeholder="Seu Sobrenome" />
                                </div>
                                <div className='col-md-3' >
                                    <label>CPF <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <InputMask mask="999.999.999-99" value={cpf} onChange={handleCpf} className="form-control" placeholder='999.999.999-99' />
                                </div>
                                <div className='col-md-3' >
                                    <label>Celular <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    {/* <input value={celular} onChange={handleCelular} type="text" className="form-control" placeholder="99 9999-9999" /> */}
                                    <InputMask mask="(99) 99999-9999" value={celular} onChange={handleCelular} className="form-control" placeholder='(99) 99999-9999' />
                                </div>
                                <div className='col-md-6' >
                                    <label>Email <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input  value={email} onChange={handleEmail} type="text" className="form-control" placeholder="email@email.com" />
                                </div>
                                <div className='col-md-6' >
                                    <label>Nome da Mãe <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input  value={mae} onChange={handleNomeMae} type="text" className="form-control" placeholder="Nome da sua Mãe" />
                                </div>
                                <div className='col-md-6' >
                                    <label>Nome do Pai <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input  value={pai} onChange={handleNomePai} type="text" className="form-control" placeholder="Nome do seu pai" />
                                </div>
                            </div>
                            <h3 className="team_page_title">Dados Endereço</h3>
                            <div className="row checkout-form" style={{ marginTop: 30 }}>
                                <div className='col-md-2' >
                                    <label>CEP <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <InputMask mask="99999999" value={cep} onChange={handleCep} className="form-control" placeholder='(99) 99999-9999' />
                                    {/* <input value={cep} onChange={handleCep} type="text" className="form-control" placeholder="66666666" /> */}
                                </div>
                                <div className='col-md-4' >
                                    <label>Endereço <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input value={endereco} onChange={handleEndereco} type="text" className="form-control" placeholder="Rua, Casa, Residencial" />
                                </div>
                                <div className='col-md-4' >
                                    <label>Bairro <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input value={bairro} onChange={handleBairro} type="text" className="form-control" placeholder="Bairro" />
                                </div>
                                <div className='col-md-2' >
                                    <label>Número <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input value={numero} onChange={handleNumero} type="text" className="form-control" placeholder="Número" />
                                </div>
                                <div className='col-md-6' >
                                    <label>Complemento</label>
                                    <input value={complemento} onChange={handleComplemento} type="text" className="form-control" placeholder="Bloco, Codominio" />
                                </div>
                                <div className='col-md-6' >
                                    <label>Referência</label>
                                    <input value={referencia} onChange={handleReferencia} type="text" className="form-control" placeholder="próximo..." />
                                </div>
                                <div className='col-md-2' >
                                    <label>UF <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input disabled value={uf} o type="text" className="form-control" placeholder="UF" />
                                </div>
                                <div className='col-md-10' >
                                    <label>Cidade <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input disabled value={cidade} type="text" className="form-control" placeholder="Cidade" />
                                </div>

                            </div>
                            <h3 className="team_page_title">Dados Escolares</h3>
                            <div className="row checkout-form" style={{ marginTop: 30 }}>
                                <div className='col-md-6' >
                                    <label>Sua Escola <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <input value={escola} onChange={handleEscola} type="text" className="form-control" placeholder="Nome da sua escola" />
                                </div>
                                <div className='col-md-3' >
                                    <label>Série <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <select  name="country" id="country" className="form-control" onChange={handleSerie} value={serie}>
                                        <option >Selecione</option>
                                        <option  value={'1'}>1 Ano </option>
                                        <option  value={'2'}>2 ano </option>
                                        <option  value={'3'}>Terceirão</option>
                                    </select>
                                </div>
                                <div className='col-md-3' >
                                    <label>Turno <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <select  name="country" id="country" className="form-control" onChange={handleTurno} value={turno}>
                                        <option >Selecione</option>
                                        <option  value={'MATUTINO'}>Matutino</option>
                                        <option  value={'VESPERTINO'}>Vespertino</option>
                                        <option  value={'NOTURNO'}>Noturno</option>
                                    </select>
                                </div>
                                <div className='col-md-3' >
                                    <label>Qtd Alunos <i style={{ color: '#BC331D' }} className="fa fa-pencil" aria-hidden="true"></i></label>
                                    <select  name="country" id="country" className="form-control" onChange={handleQtdAlunos} value={qtdAlunos}>
                                        <option >Selecione</option>
                                        <option  value={'1'}>De 10 a 20</option>
                                        <option  value={'2'}>De 20 a 40</option>
                                        <option  value={'3'}>De 40 em diante</option>
                                    </select>
                                </div>
                            </div>  

                            <div className="row checkout-form" style={{ marginTop: 30 }}>
                                <div className='col-md-6' >
                                    <button onClick={() => atualizaDados()} style={{ width: '100%' }} type="button" defaultValue="Verificar" className="btn-primary">Cadastrar</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>


            <div className="page-wrapper row">
                <Footer />
            </div>



        </>
    );
}