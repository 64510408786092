import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';

import firebase from 'firebase';
import moment from 'moment';
import { Adsense } from '@ctrl/react-adsense';

export default function Post() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [servicos] = useState({
        categoriaBlog: firebase.firestore().collection('categoriasBlog').where('status', '==', 'ATIVO'),
        blog: firebase.firestore().collection('blog')
    })

    const [categoriasBlog, setCategoriasBlog] = useState([])
    const [blog, setBlog] = useState([])
    const [post, setPost] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        servicos.categoriaBlog.get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setCategoriasBlog(data)
        })
        servicos.blog.doc(id).get().then((res) => {
            setPost(res.data())
            setLoading(false)
        })
        servicos.blog.where('status', '==', 'ATIVO').get().then((res) => {
            const data = res.docs.map((d) => d.data())
            setBlog(data)
        })
    }, [])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            {/*Featured Slider*/}
            <section className="row page-header-blog">
                <div className="container">
                    <h4>{post.id ? post.titulo : null}</h4>
                </div>
            </section>


            <div className="page-wrapper row">
                {post.id ?
                    <section className="row blog-content page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <article className="post post-type-image row">
                                        <div className="row featured-contents">
                                        <img src={post.imagem} alt="imagem"/>
                                        </div>
                                        <div className="row article-body">
                                            <h3 className="post-title"><a href={() => false}>{post.titulo}</a>
                                            </h3>
                                            <ul className="post-meta nav">
                                                <li className="post-date"><i className="fa fa-calendar-o" /> <a href={() => false}>{moment(post.dataCadastro).format('ll')}</a>
                                                </li>
                                                <li className="post-comments"><i className="fa fa-comments" /> <a href={() => false}>1 comment</a></li>
                                                <li className="posted-by"><i className="fa fa-user" /> <a href={() => false}>{post.usuario.nome}</a></li>
                                                <li className="category"><i className="fa fa-folder" />{post.categoria.categoria}</li>
                                            </ul>
                                            <div className="post-excerpt row">
                                                <p>{post.conteudo}</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-4 sidebar post-sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8105242494"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="autorelaxed"
                                    responsive={true}
                                />
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    null
                }


                <Footer />
            </div>



        </>
    );
}