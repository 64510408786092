import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Contato() {
    const [servicos] = useState({
        mail: firebase.firestore().collection('mail'),
        contato: firebase.firestore().collection('contato'),
    })
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [assunto, setAssunto] = useState('')
    const [mensagem, setMensagem] = useState('')

    function enviaEmail() {
        if (!nome) {
            mensagemAlerta('Por favor digite seu nome')
            return
        }
        if (!email || !email.includes('@') || !email.includes('.com')) {
            mensagemAlerta('Por favor o email não pode ser vazio ou no formato inválido')
            return
        }
        if (!assunto) {
            mensagemAlerta('Por favor digite o assunto do email')
            return
        }
        if (!mensagem || mensagem.length < 50) {
            mensagemAlerta('Por favor digigte sua mensagem! Mínimo de 50 letras')
            return
        }
        mensagemLoading('Enviando mensagem...')
        servicos.contato.add({
            nome:nome,
            email:email,
            assunto:assunto,
            mensagem:mensagem,
            dataCadastro:moment().format('YYYY-MM-DD HH:mm')
        }).then((res)=>{
            servicos.contato.doc(res.id).update({
                id:res.id
            }).then(()=>{
                mensagemSucesso('Mensagem enviada com sucesso! Breve entraremos em contato com voce!')
            })
        })
    }

    // Inputs
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleAssunto = (event) => {
        event.persist();
        setAssunto(event.target.value);
    }
    const handleMensagem = (event) => {
        event.persist();
        setMensagem(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(()=>{
            setNome('')
            setEmail('')
            setAssunto('')
            setMensagem('')
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <Header />
            <section className="row page-header-contato">
                <div className="container">
                    <h4>Fale Conosco</h4>
                </div>
            </section>

            <section className="row contact-content page-content" style={{ backgroundColor: 'white' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="contact_page_title">mande sua mensagem pra nós</h3>
                            <p className="contact_page_para">Estamos muito interessados em sabe o que pensa do projeto e onde podemos ajudar você.</p>
                            <div className="row m0 contact-form " >
                                <input value={nome} onChange={handleNome} type="text" className="form-control" placeholder="Nome" />
                                <input value={email} onChange={handleEmail} type="email" className="form-control" id="email" name="email" placeholder="Email" />
                                <input value={assunto} onChange={handleAssunto} type="text" className="form-control" id="subject" name="subject" placeholder="Assunto" />
                                <textarea value={mensagem} onChange={handleMensagem} name="message" id="message" placeholder="Sua mensagem" className="form-control" />
                                <p style={{ color: mensagem.length < 50 ? 'red' : 'green' }}>{mensagem.length < 50 ? mensagem.length : 50}/50</p>
                                <input onClick={() => enviaEmail()} type="submit" defaultValue="submit" className="btn-primary" />
                            </div>

                        </div>
                        <div className="col-md-4 contact-sidebar">
                            <div className="row m0 address_box">
                                <div className="inner row">
                                    <h3>Suporte ao Aluno</h3>
                                    <address>
                                        9898989898989 <br />
                                        98983333333 <br />
                                        <br />
                                        <a href={() => false}>Fale direto nocoso no WhatsApp.</a>
                                    </address>
                                </div>
                            </div>
                            <div className="row m0 address_box">
                                <div className="inner row">
                                    <h3>Empresas e Parceiros</h3>
                                    <address>
                                        9898989898989 <br />
                                        98983333333 <br />
                                        <br />
                                        <a href={() => false}>Fale direto nocoso no WhatsApp.</a>
                                    </address>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="page-wrapper row">


                <Footer />
            </div>



        </>
    );
}