import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';

import firebase from 'firebase';
import moment from 'moment';
import parse from 'html-react-parser';
import { Adsense } from '@ctrl/react-adsense';
import Swal from 'sweetalert2'


export default function Curso() {
    const queryParams = new URLSearchParams(window.location.pathname);
    const [id] = useState(window.location.pathname.replace('/curso/', ''))
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cursos: firebase.firestore().collection('cursos').where('slug', '==', id)
    })
    const [curso, setCurso] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        servicos.cursos.get().then((res) => {
            setCurso(res.docs.map((d) => d.data())[0])
            setLoading(false)
        })
    }, [])

    function verificaAluno() {
        mensagemLoading('Verificando...')
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).collection('cursos')
                    .where('idCurso', '==', curso.id)
                    .get().then((res) => {
                        if (res.size > 0) {
                            if (res.docs.map((d) => d.data())[0].status === 'CRIADO') {
                                window.location = '/checkout?c=' + res.docs.map((d) => d.data())[0].id + '&u=' + user.uid
                            } else if (res.docs.map((d) => d.data())[0].status === 'PAGAMENTO') {
                                window.location = '/checkout?c=' + res.docs.map((d) => d.data())[0].id + '&u=' + user.uid
                            } else {
                                window.location = '/meuscursos'
                            }

                        } else {
                            servicos.usuarios.doc(user.uid).collection('cursos').add({
                                idCurso: curso.id,
                                titulo: curso.titulo,
                                descricao: curso.descricao,
                                miniatura: curso.miniatura,
                                imagem: curso.imagem,
                                valor: curso.valor,
                                categoria: curso.categoria,
                                aulaAtual: parseInt(0),
                                qtdAulas: parseInt(0),
                                totalAulas: parseInt(0),
                                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                                dataPagamento: null,
                                status: 'CRIADO'
                            }).then((res) => {
                                servicos.usuarios.doc(user.uid).collection('cursos').doc(res.id).update({
                                    id: res.id
                                }).then(() => {
                                    window.location = '/checkout?c=' + res.id + '&u=' + user.uid
                                }).catch(() => {

                                })
                            })

                        }
                    })
            } else {
                window.location = '/login'
            }
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            {/*Featured Slider*/}
            <section className="row page-header-cursos">
                <div className="container">
                    <h4>{curso.id ? curso.titulo : null}</h4>
                </div>
            </section>


            <div className="page-wrapper row">
                {curso.id ?
                    <section className="row gallery-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 single-project" style={{ marginTop: 15 }}>
                                    <div className="featured-content">
                                        <div className="item"><img style={{ height: '100%', width: '100%' }} src={curso.imagem} alt="imagem" /></div>
                                    </div>
                                    <article className="post post-type-image row" style={{ marginTop: 20 }}>
                                        <div className='row article-body'>
                                            <ul className="post-meta nav">
                                                <li className="post-date"><i className="fa fa-users" /> 12434 alunos</li>
                                                <li className="post-comments"><i className="fa fa-comments" /> 121211212</li>
                                                <li className="posted-by"><i className="fa fa-calendar-o" />Última atualização em {moment(curso.dataAtualizacao).format('MM/YYYY')} </li>
                                                <li className="category"><i className="fa fa-folder" />{curso.categoria}</li>
                                            </ul>
                                        </div>
                                    </article>
                                    <div className="row m0 event_title" style={{ marginTop: 20 }}>
                                        <h2 className="hhh h1 pull-left">{curso.titulo}</h2>
                                        {curso.desconto ?
                                            <h2 className="hhh h1 pull-right" style={{ color: '#D8D8D8', fontSize: 18, marginTop: 5, textDecoration: 'line-through' }}>{curso.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                            :
                                            null
                                        }

                                        <h2 className="hhh h1 pull-right" style={{ color: '#125431', marginRight: 5 }}>{curso.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h2>
                                    </div>
                                    <h4>{curso.descricao}</h4>
                                    <h4><b>Sobre o Curso</b></h4>
                                    {curso.conteudo ? parse(curso.conteudo) : ''}
                                    <h4><b>Requisitos</b></h4>
                                    <p>{curso.requisitos ? parse(curso.requisitos) : ''}</p>
                                    <div className="pull-left block"><a onClick={() => verificaAluno()} href={() => false} className="btn-primary btn-outline">Me inscrever</a></div>
                                    <div className="row shareOnRow m0">
                                        <ul className="list-unstyled">
                                            <li>COMPARTILHE</li>
                                            <li><a href={() => false}><i className="fa fa-facebook" /></a></li>
                                            <li><a href={() => false}><i className="fa fa-instagram" /></a></li>
                                            <li><a href={() => false}><i className="fa fa-whatsapp" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 sidebar event-sidebar">
                                    <div className="row m0 widget widget-similar-project widget-similar">
                                        <div className="similar-project">
                                            <div className="row event-listing">
                                                <div className="event_excepts row m0">
                                                    <h4 className="event_title"><a href="single-event.html">{curso.titulo}</a></h4>
                                                    <h5 className="event-place">{curso.descricao}</h5>
                                                    <p>Gostou do que o curso pode oferecer? Inscreva-se agora mesmo</p>
                                                    <a onClick={() => verificaAluno()} href={() => false} className="btn-primary btn-outline">Me inscrever</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 sidebar post-sidebar">
                                    <Adsense
                                        client="ca-pub-5658260251546452"
                                        slot="5379840950"
                                        style={{ display: 'block' }}
                                        layout="in-article"
                                        format="auto"
                                        responsive={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    null
                }




                <Footer />
            </div>



        </>
    );
}