import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';

import firebase from 'firebase';
import { Adsense } from '@ctrl/react-adsense';

export default function Projeto() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [servicos] = useState({
        projetos: firebase.firestore().collection('projetos')
    })

    const [projeto, setProjeto] = useState({})
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        if (id) {
            servicos.projetos.doc(id).get().then((res) => {
                setProjeto(res.data())
                setLoading(false)
            })
        } else {
            window.history.back()
        }
    }, [])
    if (loading) {
        return (
            <div className='loader-container'/>
        )
    }
    return (
        <>
            <Header />
            {/*Featured Slider*/}
            <section className="row page-header-projetos">
                <div className="container">
                    <h4>{projeto.titulo}</h4>
                </div>
            </section>


            <div className="page-wrapper row">
                <section className="row gallery-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="featured-content">
                                    <div className="item"><img src={projeto.imagem} alt="imagem"/></div>

                                </div>
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">{projeto.titulo}</h2>
                                </div>
                                <h4>{projeto.descricao}</h4>
                                <p style={{ textAlign: 'justify' }}>{projeto.conteudo}</p>
                                <div className="row featureswithImage">
                                    <div className="col-sm-6">
                                        <img src={projeto.miniatura} alt="imagem"className="img-responsive" />
                                    </div>
                                    <div className="col-sm-6">
                                        <h4 className="list-heading">Nosso objetivo</h4>
                                        <ul className="list-unstyled">
                                            <li>{projeto.caracteristica1}</li>
                                            <li>{projeto.caracteristica2}</li>
                                            <li>{projeto.caracteristica3}</li>
                                            <li>{projeto.caracteristica4}</li>
                                            <li>{projeto.caracteristica5}</li>
                                            <li>{projeto.caracteristica6}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="text-block row">{projeto.frase}</div>
                                <div className="row featureswithImage">
                                    <div className="col-sm-6 col-sm-push-6">
                                        <ul className="list-unstyled">
                                            <li>Doe celulares que não são mais usados</li>
                                            <li>Doe Tablets que não são mais usados</li>
                                            <li>Você é tecnico em manutenção? Saiba como ajudar</li>
                                            <li>Doe esperança </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 col-sm-pull-6">
                                        <h4 className="list-heading">Como você pode doar</h4>
                                        <p>Nem sempre tudo é dinheiro! Basta ter bom coração</p>
                                        <ul className="list-unstyled">
                                            <li>Doe através de PIX</li>
                                            <li>Cartão de Crédito</li>
                                            <li>Doação de equipamentos antigos (em funcionamento)</li>
                                            <li>Divulgue nosso projeto para alcançar mais pessoas</li>
                                        </ul>
                                    </div>
                                </div>
                                <h4>Quer poder doar itens maiores?</h4>
                                <p>Saiba que temos um contato para parceiros</p>
                                <div className="row shareOnRow m0">
                                    <a href={() => false} className="btn-primary pull-left">Quero doar mais coisas</a>
                                    <br className="visible-xs" />
                                    <ul className="list-unstyled">
                                        <li>COMPARTILHE EM</li>
                                        <li><a href={() => false}><i className="fa fa-facebook" /></a></li>
                                        <li><a href={() => false}><i className="fa fa-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                
                                <div className="row m0 widget widget-similar-project widget-similar">
                                    <div className="similar-project">
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={() => false}>{projeto.responsavel}</a></h4>
                                                <p style={{fontSize:13}}>{projeto.email}</p>
                                                <p style={{fontSize:13}}>Ligue pra nós - {projeto.contato}</p>
                                                <a href={() => false} className="btn-primary btn-outline">Saiba Mais sobre o projeto</a>
                                            </div>
                                        </div>
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={() => false}>DOE ONLINE</a></h4>
                                                <p  style={{fontSize:13}}>PIX {projeto.tipoPix}: {projeto.chavePix} <br /><b>{projeto.favorecido}</b></p>
                                                <p  style={{fontSize:13}}>Se preferir mais opções de pagamento</p>
                                                <a href={() => false} className="btn-primary btn-outline">Clique aqui e saiba como doar</a>
                                            </div>
                                        </div>
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href={() => false}>Quer ajudar a criar sonhos virtuais?</a>
                                                </h4>
                                                <p>Entre em contato conosco para saber mais sobre o Projeto Escola Fábrica de Sonhos Virtuais</p>
                                                <a href={() => false} className="btn-primary btn-outline">Falar no WhatsApp</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="5379840950"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>



                <Footer />
            </div>



        </>
    );
}