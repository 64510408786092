import React, { useEffect, useState } from 'react';

// Componentes
import Header from '../../componentes/Header'
import Footer from '../../componentes/Footer';

export default function Responsabilidade() {
    return (
        <>
            <Header />
            {/*Featured Slider*/}

            <div>
                <section className="row page-header">
                    <div className="container">
                        <h4>Termos de Privacidade</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{backgroundColor:'white'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="row m0 " style={{marginTop:20}}>
                                    <h2 className="hhh">Termos de Privacidade - Última atualização: 20/06/2023</h2>
                                   
                                </div>
                                <h4 style={{marginTop:20,textAlign:'justify'}}>Bem-vindo à plataforma de cursos online Projeto Escola Fábrica de Sonhos Virtuais (doravante referida como "nós" ou "nossa"). Nós valorizamos a privacidade dos nossos usuários e estamos comprometidos em proteger as informações pessoais que coletamos e utilizamos. Este documento estabelece os termos e as condições de privacidade que regem o uso da nossa plataforma. Ao utilizar nossos serviços, você concorda com os seguintes termos:</h4>
                                <h4><b>1. Coleta de Informações</b></h4>
                                <p style={{textAlign:'justify'}}>1.1 Informações Pessoais: Para fornecer uma experiência personalizada e melhorar nossos serviços, podemos coletar informações pessoais, como nome, endereço de e-mail, número de telefone, idade, sexo, país de residência e outras informações relevantes. Essas informações podem ser solicitadas no momento do cadastro ou durante o uso da plataforma.</p>
                                <p style={{textAlign:'justify'}}>1.2 Informações de Pagamento: Quando você realiza transações financeiras em nossa plataforma, coletamos as informações necessárias para processar o pagamento, como detalhes do cartão de crédito ou outras informações de pagamento.</p>
                                <p style={{textAlign:'justify'}}>1.3 Informações de Uso: Podemos coletar informações sobre como você interage e utiliza nossa plataforma, incluindo dados de acesso, endereço IP, tipo de navegador, páginas visitadas, tempo gasto nas páginas, entre outras informações de uso.</p>
                                <h4><b>2. Uso das Informações</b></h4>
                                <p style={{textAlign:'justify'}}>2.1 Utilização Interna: As informações coletadas serão utilizadas internamente para melhorar nossos serviços, personalizar a experiência do usuário, fornecer suporte técnico, processar pagamentos, enviar notificações relevantes e realizar análises estatísticas.</p>
                                <p style={{textAlign:'justify'}}>2.2 Comunicações: Podemos enviar comunicações relacionadas aos nossos serviços, como atualizações, newsletters ou informações sobre novos cursos. Você pode optar por não receber essas comunicações, caso não deseje recebê-las.</p>
                                <p style={{textAlign:'justify'}}>2.3 Compartilhamento com Terceiros: Não compartilharemos suas informações pessoais com terceiros, exceto quando exigido por lei ou quando necessário para fornecer serviços relacionados, como processadores de pagamento ou prestadores de serviços de hospedagem. Esses terceiros estão sujeitos a obrigações de confidencialidade e só podem usar suas informações pessoais conforme estritamente necessário para cumprir suas funções.</p>
                                <h4><b>3. Segurança das Informações</b></h4>
                                <p style={{textAlign:'justify'}}>3.1 Medidas de Segurança: Implementamos medidas de segurança adequadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, é importante ressaltar que nenhum método de transmissão ou armazenamento eletrônico é completamente seguro, e não podemos garantir a segurança absoluta das informações.</p>
                                <h4><b>4. Cookies e Tecnologias Semelhantes</b></h4>
                                <p style={{textAlign:'justify'}}>4.1 Cookies: Podemos utilizar cookies e outras tecnologias semelhantes para coletar informações sobre o uso da plataforma, personalizar a experiência do usuário, fornecer conteúdo relevante e melhorar nossos serviços. Você pode gerenciar suas preferências de cookies nas configurações do seu navegador.</p>
                                <h4><b>5. Acesso e Atualização de Informações Pessoais</b></h4>
                                <p style={{textAlign:'justify'}}>5.1 Acesso e Correção: Você tem o direito de acessar, corrigir e atualizar suas informações pessoais armazenadas em nossa plataforma. Para exercer esse direito, entre em contato conosco por meio dos canais de suporte fornecidos.</p>
                                <h4><b>6. Alterações nesta Política de Privacidade</b></h4>
                                <p style={{textAlign:'justify'}}>6.1 Alterações: Podemos atualizar esta Política de Privacidade periodicamente, conforme necessário. A versão mais recente estará sempre disponível em nosso site. Recomendamos que você revise regularmente esta política para estar ciente de quaisquer alterações.</p>
                                <h4><b>7. Contato</b></h4>
                                <p style={{textAlign:'justify'}}>7.1 Dúvidas: Se você tiver dúvidas ou preocupações relacionadas à nossa Política de Privacidade, entre em contato conosco por meio dos canais de suporte fornecidos em nossa plataforma.</p>
                                <div className="text-block row">Ao utilizar nossa plataforma, você reconhece e concorda com os termos e condições estabelecidos nesta Política de Privacidade.</div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <div className="row m0 widget widget-similar-project widget-similar">
                                    <div className="similar-project">
                                       
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href="/contato">Dúvidas?</a></h4>
                                                <p>Para mais esclarecimentos sobre os termos de privacidade da plataforma, entre em contato com conosco que tiramos todas as dúvidas sobre o Projeto</p>
                                                <a href="/contato" className="btn-primary btn-outline">fale conosco</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
            </div>

        </>
    );
}