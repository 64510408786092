import React from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import { Adsense } from '@ctrl/react-adsense';

export default function Sobre() {
    return (
        <>
            <Header />

            <div>
                <section className="row page-header-sobre">
                    <div className="container">
                        <h4>Escola Fábirca de Sonhos Virtuais</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="featured-content">
                                    <div className="item"><img src="/images/sobre/sobre.png" alt="imagem" /></div>
                                </div>
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">Abrindo portas para o futuro</h2>
                                    <a href={() => false} className="btn-primary pull-right">Fale conosco</a>
                                </div>
                                <h4>Desperte sua imaginação e transforme sonhos em realidade na Escola Fábrica de Sonhos Virtuais </h4>
                                <h4><b>O lugar onde a criatividade ganha vida digital!</b></h4>
                                <p style={{ textAlign: 'justify' }}>A Escola Fábrica de Sonhos Virtuais é um lugar mágico onde a criatividade e a tecnologia se encontram para transformar ideias em realidade. Nessa escola inovadora, os alunos são encorajados a explorar suas paixões, ampliar suas habilidades e descobrir novas formas de expressão por meio do mundo digital. </p>
                                <p style={{ textAlign: 'justify' }}>Ao adentrar a Escola Fábrica de Sonhos Virtuais, os alunos são recebidos por um ambiente inspirador. Cada espaço é cuidadosamente projetado para estimular a imaginação e o aprendizado, proporcionando aos estudantes uma experiência educacional única.</p>
                                <p style={{ textAlign: 'justify' }}>Os programas de ensino da escola são diversificados e abrangentes, cobrindo uma ampla gama de disciplinas virtuais, como programação, manutençao de computadores e muito mais. Os alunos têm a oportunidade de mergulhar em projetos práticos e colaborativos, trabalhando em equipes para criar produtos digitais inovadores.</p>
                                <p style={{ textAlign: 'justify' }}>Além disso, a escola mantém parcerias estratégicas com empresas e profissionais da indústria, proporcionando aos alunos oportunidades de estágio, mentoria e networking. Essas conexões ajudam a preparar os estudantes para o mundo profissional, permitindo que eles construam uma base sólida para suas carreiras no campo digital.</p>
                                <p style={{ textAlign: 'justify' }}>A Escola Fábrica de Sonhos Virtuais é um lugar onde os sonhos se transformam em realidade. É um ambiente vibrante, onde a tecnologia se une à criatividade para capacitar os alunos a se tornarem os criadores e inovadores do futuro digital. Aqui, os sonhos ganham vida e os estudantes são encorajados a trilhar seu próprio caminho no mundo fascinante dos sonhos virtuais.</p>
                                <div className="row featureswithImage">
                                    <div className="col-sm-6">
                                        <img src="https://themedesigner.in/demo/Helping-Hands/images/gallery/project.jpg" alt="imagem" className="img-responsive" />
                                    </div>
                                    <div className="col-sm-6">
                                        <h4 className="list-heading">5 motivos para estudar conosco</h4>
                                        <ul className="list-unstyled">
                                            <li>Exploração criativa</li>
                                            <li>Tecnologia atual</li>
                                            <li>Linguagem de fácil entendimento</li>
                                            <li>Projetos práticos e colaborativos</li>
                                            <li>Conexões com o mundo digital</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="text-block row">Estudar na Escola Fábrica de Sonhos Virtuais é a escolha ideal para aqueles que desejam mergulhar no mundo da criatividade digital, adquirir habilidades relevantes e construir um futuro promissor na indústria dos sonhos virtuais.</div>

                                <div className="row shareOnRow m0">
                                    <a href={() => false} className="btn-primary pull-left">venha fazer parte da nossa família</a>
                                    <br className="visible-xs" />
                                    <ul className="list-unstyled">
                                        <li>Segue la</li>
                                        <li><a href={() => false}><i className="fa fa-facebook" /></a></li>
                                        <li><a href={() => false}><i className="fa fa-linkedin" /></a></li>
                                        <li><a href={() => false}><i className="fa fa-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <div className="row m0 widget widget-category">
                                    <h4 className="widget-title">CATEGORIAS</h4>
                                    <ul className="nav">
                                        <li><a href={() => false}>PROGRAMAÇÃO</a></li>
                                        <li><a href={() => false}>MANUTENÇÃO COMPUTADORES</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="8105242494"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="autorelaxed"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    );
}