import React, { useEffect, useState } from 'react';
import firebase from 'firebase';

export default function Header() {
    const [servicos] = useState({
        auth: firebase.auth(),
        mail: firebase.firestore().collection('mail'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [page, setPage] = useState('')
    const [usuario, setUsuario] = useState({})
    const [altura] = useState(window.screen.height)
    const [largura] = useState(window.screen.width)
    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
            }
        })
        setPage(window.location.pathname.replace('/', ''))
    }, [])
    return (

        <>
            <div>
                <nav className="navbar navbar-default navbar-static-top navbar2">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#mainNav" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="mainNav">
                            <ul className="nav navbar-nav">
                                <li className={page === '' ? "dropdown active" : "dropdown"}><a href="/">Início</a></li>
                                <li className={page === 'sobre' || page === 'sobreCursos' || page === 'sobreParceiros' ? "dropdown active" : "dropdown"}>
                                    <a href={() => false} className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">O projeto</a>
                                    <ul className="dropdown-menu">
                                        <li><a href="/sobre">Sobre</a></li>
                                        <li><a href="/sobreCursos">Dos Cursos</a></li>
                                        <li><a href="/sobreParceiros">Parceiros</a></li>
                                    </ul>
                                </li>
                                <li className={page === 'blog' ? "dropdown active" : "dropdown"}><a href="/blog">Blog</a></li>
                                <li className={page === 'cursos' ? "dropdown active" : "dropdown"}><a href="/cursos">Cursos</a></li>
                                <li className={page === 'projetos' || page === 'projeto' ? "dropdown active" : "dropdown"}><a href="/projetos">Projetos</a></li>
                                <li className={page === 'sejaParceiro' ? "dropdown active" : "dropdown"}><a href="/sejaParceiro">Seja um parceiro</a></li>
                                <li className={page === 'contato' ? "dropdown active" : "dropdown"}><a href="/contato">Contato</a></li>

                            </ul>
                            <ul className="nav social_navbar navbar-right">
                                <li><a href="https://www.facebook.com/profile.php?id=100092472712188"><i className="fa fa-facebook" /></a></li>
                                <li><a href="https://www.instagram.com/escolafabricadesonhosvirtuais"><i className="fa fa-instagram" /></a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <header className="row header1">
                    <div className="container">
                        <div className="logo pull-left">
                            <a href="/"><img src="images/logo_verde_2.png" alt="imagem" /></a>
                        </div>
                        {usuario.id ?
                            <a href="/perfil" className="btn-primary btn-outline hidden-sm hidden-xs pull-right">Olá, {usuario.nome}</a>
                            :
                            <a href="/login" className="btn-primary btn-outline hidden-sm hidden-xs pull-right">Área do Aluno</a>
                        }

                        <div className="pull-right emergency-contact">
                            <div className="pull-left">
                                <span><i className="fa fa-envelope-o" /></span>
                                <div className="infos_col">
                                    <h6>email</h6>
                                    <a href="mailto:contato@fabricasonhosvirtuais.com.br">
                                        <h5>contato@fabricasonhosvirtuais.com.br</h5>
                                    </a>
                                </div>
                            </div>
                            <div className="pull-left">
                                <span className="rotate"><i className="fa fa-phone" /></span>
                                <div className="infos_col">
                                    <h6>dê um alõ</h6>
                                    <h4>9898989898989</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {largura < 1000 ?
                    <nav className="navbar navbar-default ">
                        <div className="container">
                            {usuario.id ?
                                <a href="/perfil" className="btn-primary btn-outline ">Olá, {usuario.nome}, acesse seu perfil</a>
                                :
                                <a href="/login" className="btn-primary btn-outline s">Área do Aluno</a>
                            }
                        </div>
                    </nav>
                    :
                    null
                }

            </div>

        </>
    );
}