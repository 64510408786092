import React from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';
import { Adsense } from '@ctrl/react-adsense';

export default function SobreCursos() {
    return (
        <>
            <Header />
            {/*Featured Slider*/}

            <div>
                <section className="row page-header-cursos">
                    <div className="container">
                        <h4>Sobre nossos cursos</h4>
                    </div>
                </section>
                <section className="row gallery-content" style={{backgroundColor:'white'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 single-project">
                                <div className="featured-content">
                                
                                    <div className="item"><img src="https://themedesigner.in/demo/Helping-Hands/images/gallery/project.jpg" alt="imagem"/></div>
                                </div>
                                <div className="row m0 project_title">
                                    <h2 className="hhh h1 pull-left">O que você terá ao final de cada curso..</h2>
                                    <a href={() => false} className="btn-primary pull-right">Fale conosco</a>
                                </div>
                                <h4>Desperte sua imaginação e transforme sonhos em realidade na Escola Fábrica de Sonhos Virtuais </h4>
                                <h4><b>O lugar onde a criatividade ganha vida digital!</b></h4>
                                <p style={{textAlign:'justify'}}>Bem-vindo à Escola Fábrica de Sonhos Virtuais, o lugar onde a imaginação se transforma em realidade! Nossos cursos são projetados para aqueles que desejam explorar e dominar as habilidades necessárias para criar e trazer à vida seus próprios sonhos virtuais.</p>
                                <p style={{textAlign:'justify'}}>Com uma abordagem prática e orientada para projetos, nossos cursos fornecem conhecimentos teóricos e práticos essenciais para dar vida a ideias criativas. Nossos instrutores são profissionais experientes e apaixonados, prontos para compartilhar seus conhecimentos e orientá-lo em sua jornada virtual.</p>
                                <p style={{textAlign:'justify'}}>Além disso, valorizamos a comunidade e a colaboração. Acreditamos que o trabalho em equipe é essencial para o sucesso na criação de sonhos virtuais. Portanto, nossos cursos incentivam a colaboração entre os alunos, proporcionando oportunidades de aprendizado conjunto e networking valioso.</p>
                                <p style={{textAlign:'justify'}}>Ao concluir um curso na Escola Fábrica de Sonhos Virtuais, você estará equipado com as habilidades e conhecimentos necessários para transformar suas ideias em realidade virtual. Seja para criar sites, ajeitar vários computadores ou desenvolver experiências interativas inovadoras, nossos cursos oferecem o caminho para alcançar seus sonhos virtuais.</p>
                                <p style={{textAlign:'justify'}}>Junte-se a nós na Escola Fábrica de Sonhos Virtuais e embarque nessa jornada emocionante rumo à realização de seus sonhos virtuais. Venha explorar, aprender e criar conosco, enquanto transformamos sua imaginação em uma experiência virtual extraordinária!</p>
                                
                                <div className="text-block row">Espero que isso possa ajudá-lo a atrair parceiros interessados nos cursos da Escola Fábrica de Sonhos Virtuais!</div>
                                
                                <div className="row shareOnRow m0">
                                    <a href={() => false} className="btn-primary pull-left">venha fazer parte da nossa família</a>
                                    <br className="visible-xs" />
                                    <ul className="list-unstyled">
                                        <li>Segue la</li>
                                        <li><a href={() => false}><i className="fa fa-facebook" /></a></li>
                                        <li><a href={() => false}><i className="fa fa-linkedin" /></a></li>
                                        <li><a href={() => false}><i className="fa fa-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <div className="row m0 widget widget-category">
                                    <h4 className="widget-title">CATEGORIAS</h4>
                                    <ul className="nav">
                                        <li><a href={() => false}>PROGRAMAÇÃO</a></li>
                                        <li><a href={() => false}>MANUTENÇÃO COMPUTADORES</a></li>
                                    </ul>
                                </div>
                                <div className="row m0 widget widget-similar-project widget-similar">
                                    <div className="similar-project">
                                        <div className="row gallery-item">
                                            <div className="inner row">
                                                <h4 className="title"><a href="single-project.html">Exploração criativa</a></h4>
                                                <p>Você terá a liberdade de explorar sua criatividade ao máximo. Seja na programação,na manutenção você encontrará um ambiente estimulante para transformar suas ideias em realidade digital.</p>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 single-project-sidebar sidebar">
                                <Adsense
                                    client="ca-pub-5658260251546452"
                                    slot="5379840950"
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="auto"
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
            </div>

        </>
    );
}