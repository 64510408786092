import React from 'react';

// Componentes
import Header from '../componentes/Header'
import Footer from '../componentes/Footer';

export default function Page404() {
    return (
        <>
            <Header />
            <section className="row content_404">
                <div className="container">
                    <div className="row error_message">
                        <h2 className="error_no">404</h2>
                        <h2 className="error_details">Página não encontrada</h2>
                        <div className="row m0 buttons">
                            <a href="/" className="btn-primary">Ir para início</a>
                            {/* <a href="events.html" className="btn-primary btn-outline dark">view our program</a> */}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}